<template>
  <div class="vs-pagination--mb">
    <div class="con-vs-pagination vs-pagination-primary">
      <div class="vs-pagination--nav">
        <button
          class="vs-pagination--buttons btn-prev-pagination vs-pagination--button-prev"
          @click="$emit('prev')"
          :class="currentPage === 1 ? 'disabled' : ''"
          :disabled="currentPage === 1"
        >
          <i class="vs-icon notranslate icon-scale material-icons null">chevron_left</i>
        </button>
        <ul class="vs-pagination--ul">
          <li
            v-for="(item, index) in totalPages"
            :key="index"
            class="item-pagination vs-pagination--li"
            :class="currentPage === item ? 'is-current' : ''"
            @click="$emit('goToPage', index)"
          >
            <span>{{ item }}</span>
            <div class="effect"></div>
          </li>
        </ul>
        <button
          class="vs-pagination--buttons btn-next-pagination vs-pagination--button-next"
          @click="$emit('next')"
          :class="currentPage === totalPages ? 'disabled' : ''"
          :disabled="currentPage === totalPages"
        >
          <i class="vs-icon notranslate icon-scale material-icons null">chevron_right</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPagination",
  props: {
    prev: {
      type: Function,
      required: false
    },
    next: {
      type: Function,
      required: false
    },
    goToPage: {
      type: Function,
      required: false
    },
    totalPages: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  }
}
</script>
