<template>
  <div class="flex items-center">
    <vs-button
      v-if="hasInbox"
      color="dark"
      type="flat"
      icon-pack="feather"
      icon="icon-link"
      @click="openWebhook"
      class="mt-1"
    />
    <vs-button
      v-if="hasInbox"
      color="warning"
      type="flat"
      icon-pack="feather"
      icon="icon-edit"
      @click="openEdit"
      class="mt-1"
    />
    <vs-button
      type="flat"
      icon-pack="feather"
      icon="icon-trash"
      color="danger"
      @click="confirmDeleteTemplate"
      class="mt-1"
    />

    <vs-popup
      class="holamundo"
      :title="$t('View')"
      :active.sync="popupViewActive"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <p>{{ title }}</p>
          <vs-textarea
            name="message"
            v-model="message"
            class="w-full my-3"
            rows="10"
            disabled
            placeholder="Escreva uma mensagem..."
          >
          </vs-textarea>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllWppTemplates: false,
      popupActive: false,
      popupViewActive: false,
      popupWhatsappActive: false,
      title: "",
      message: "",
      chatFilter: null,
      currentChat: null,
      allWppTemplates: [],
      wppTemplateCategory: null,
      currentWppTemplate: null,
      quickMessage: null,
      wppTemplate: {
        category: null,
        name: "",
        language: "pt_BR",
        components: [
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: "",
              },
            ],
          },
        ],
      },
    };
  },
  name: "CellRendererActions360Dialog",
  computed: {
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
  },
  methods: {
    openWppPopup() {
      this.params.context.componentParent.openWppPopup(this.params.value);
    },
    openEdit() {
      this.params.context.componentParent.openEdit(this.params.data.id);
    },
    openWebhook() {
      this.params.context.componentParent.openWebhook(this.params.data.id);
    },
    confirmDeleteTemplate() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmRemoveUserAccess"),
        text: this.$t("ConfirmRemoveQuickMessage"),
        accept: this.deleteTemplate,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    deleteTemplate() {
      this.params.context.componentParent.deleteTemplate(this.params.data.id);
    },
  },
};
</script>
<style>
.vs-tooltip {
  z-index: 999000;
}
</style>
