<template>
  <div class="flex flex-col mt-2">
    <small class="mb-1">{{ $t("Button") }} {{ index + 1 }} ({{button.text.length}}/20)</small>
    <div class="flex flex-1" v-if="buttonType === 'QUICK_REPLY'">
      <div class="flex w-full relative">
      <vs-input maxlength="20" class="flex-1 mr-1" v-model="button.text" placeholder="Label" />
        <span class="font-semibold absolute floatCounter">
            {{ button.text.length }}
        </span>
      </div>
      <vs-button
        icon-pack="feather"
        icon="icon-x"
        size="medium"
        color="danger"
        @click="remove"
      ></vs-button>
    </div>
    <div v-else>
      <div class="flex">
        <div class="flex flex-col w-full mr-1">
          <div class="relative">
          <vs-input
            maxlength="20"
            class="w-full mb-1"
            v-model="button.text"
            placeholder="Label"
            :disabled="isDisabled"
          />
          <span class="font-semibold absolute floatCounter">
            {{ button.text.length}}
          </span>
          </div>
          <vs-input
            class="w-full"
            v-model="button.action"
            placeholder="Action"
            :disabled="isDisabled"
          />
        </div>
        <div
          class="rounded-lg cursor-pointer flex items-center justify-center py-5 px-10 text-white"
          :class="disabled ? 'disabled' : 'bg-danger shadow-red'"
          @click="isDisabled ? () => null : remove()"
        >
          <feather-icon icon="XIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonSubTypes: ["URL", "PHONE_NUMBER"],
    };
  },
  props: ["button", "buttonType", "index", "disabled"],
  methods: {
    remove() {
      if (this.isDisabled) return;
      this.$emit("remove");
    },
  },
  computed: {
    isDisabled() {
      return this.disabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.shadow-red {
  &:hover {
    box-shadow: 0.001px 0.001px 2px red;
  }
}
.disabled {
  background-color: rgba(255, 0, 0, 0.336);
}

.floatCounter {
  left: calc(100% - 30px);
  top: 8px;
  color: #999;
}
</style>
