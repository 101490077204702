<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
========================================================================================== -->

<template>
  <div id="page-user-list">
    <!-- AgGrid Table -->
    <ag-grid-vue
      @gridReady="onGridReady"
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      class="ag-theme-material my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="messagesData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

// Store Module

// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererActivate from "./cell-renderer/CellRendererActivate.vue";

import vSelect from "vue-select";

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    // Cell Renderer
    CellRendererActions,
    CellRendererActivate,
    "v-select": vSelect,
  },
  data() {
    return {
      inboxID: null,
      inboxIntentions: [],
      acl: "",
      aclOptions: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: this.$t("Manager"),
          value: "manager",
        },
        {
          label: this.$t("Operator"),
          value: "operator",
        },
      ],
      title: "",
      message: "",
      searchQuery: "",
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$t("Type"),
          field: "title",
          filter: true,
          cellStyle: (params) =>
            params.data.status === 0
              ? { "pointer-events": "none", opacity: "0.5" }
              : "",
        },
        {
          headerName: this.$t("Message"),
          field: "text",
          filter: true,
          cellStyle: (params) =>
            params.data.status === 0
              ? { "pointer-events": "none", opacity: "0.5" }
              : "",
        },
        {
          headerName: "Editar",
          field: "type",
          cellRenderer: "CellRendererActions",
          width: 50,
        },
        {
          headerName: "Ativa",
          field: "type",
          cellRenderer: "CellRendererActivate",
          width: 50,
        },
      ],
      components: {
        CellRendererActions,
        CellRendererActivate,
      },
      inboxAutoMessagesFiltered: [],
    };
  },
  computed: {
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    messagesData() {
      return this.$store.state.chat.inboxAutoMessages;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    getAutoMessages() {
      this.$vs.loading();
      this.$http
        .get("/p/chat/inbox/autoMessages", {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          this.$vs.loading.close();
          const inboxAutoMessagesFiltered = response.data.data.map(
            (message) => {
              message.title = this.getAutoMessageTitle(message.type);
              return message;
            }
          );
          await this.$store.dispatch(
            "chat/setInboxAutoMessages",
            inboxAutoMessagesFiltered
          );
          this.inboxAutoMessagesFiltered = inboxAutoMessagesFiltered;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    getAutoMessageTitle(type) {
      switch (type) {
        case "start_flow":
          return this.$t("FlowStart");
        case "start_attendance":
          return this.$t("StartAttendance");
        case "operator_forward":
          return this.$t("OperatorForward");
        case "nps_survey":
          return this.$t("CloseConversationNpsSurvey");
        case "csat_survey":
          return this.$t("CloseConversationCsatSurvey");
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    this.getAutoMessages();
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
