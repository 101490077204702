<template>
  <vs-row>
    <vs-col vs-w="12">
      <h3 class="mb-5 mt-10">
        {{ $t("Settings") }}
      </h3>
    </vs-col>

    <vs-tabs
      v-model="activeTab"
      :position="isSmallerScreen ? 'top' : 'left'"
      class="tabs-shadow-none"
      id="profile-tabs"
      :key="isSmallerScreen"
    >
      <vs-tab
        icon-pack="feather"
        icon="icon-award"
        :label="!isSmallerScreen ? $t('Account') : ''"
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <acc-settings />
        </div>
      </vs-tab>
      <vs-tab
        icon-pack="feather"
        icon="icon-users"
        :label="!isSmallerScreen ? $t('Team') : ''"
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <acc-team />
        </div>
      </vs-tab>
      <vs-tab
        id="inboxConfigTab"
        icon-pack="feather"
        icon="icon-inbox"
        :label="!isSmallerScreen ? $t('Inbox') : ''"
        v-show="this.hasInbox"
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <inbox-settings />
        </div>
      </vs-tab>
      <vs-tab
        icon-pack="feather"
        icon="icon-zap"
        :label="!isSmallerScreen ? $t('Duotalk IA') : ''"
        v-if="accHasAIFeature"
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <duotalk-ia-settings />
        </div>
      </vs-tab>
      <vs-tab
        icon-pack="feather"
        icon="icon-file-text"
        v-show="this.hasInbox && $store.state.user.userRole === 'master'"
        :label="!isSmallerScreen ? $t('Extratc') : ''"
      >
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <extract-list />
        </div>
      </vs-tab>
    </vs-tabs>
  </vs-row>
</template>

<script>
import AccSettings from "./AccView.vue";
import AccTeam from "./user-list/UserList.vue";
import InboxSettings from "./inbox-config/InboxSettings.vue";
import DuotalkIaSettings from "@/components/duotalk-ia-settings/DuotalkIaSettings.vue";
import ExtractList from "./extract/ExtractList.vue";

export default {
  components: {
    AccSettings,
    AccTeam,
    InboxSettings,
    DuotalkIaSettings,
    ExtractList,
  },
  data() {
    return {
      hasInbox: false,
      activeTab: 0,
    };
  },
  computed: {
    currentAccId() {
      return this.$store.state.acc.current_acc.id;
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    usersData() {
      return this.$store.state.acc.current_acc_users;
    },
    accHasAIFeature() {
      return (
        this.$store.state.acc.current_acc.aiEnable ||
        this.currentAccId === "60ad40a8cf24431d122b2433" ||
        this.currentAccId === "608c60d25e9671ec25384820" ||
        this.currentAccId === "5f5a4806e7a7bc318ae589cc" ||
        this.currentAccId === "64e7286b799c8d04217445ee" ||
        this.currentAccId === "626a9da67edc070332477a55" ||
        this.currentAccId === "626a9d947edc07e34c4776a1" ||
        this.currentAccId === "64f774f21bb62e5a0f0ae7be" ||
        this.currentAccId === "634f2828d73776a6886df536" ||
        this.currentAccId === "638ddbce63ef447b55b5f6b1" ||
        this.currentAccId === "63286decb79ebf3b3384367e" ||
        this.currentAccId === "6298bbf57d445a5dec90e060" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "62c5a4e1f45755f275bd8d1d" ||
        this.currentAccId === "623b367aadbb4d4aa6802372" ||
        this.currentAccId === "63a36fd60a079cd7b75de1ed" ||
        this.currentAccId === "63a36fe60a079c2e6d5de573" ||
        this.currentAccId === "647de082c17f5f6bc518bcca" ||
        this.currentAccId === "647de0c5f88ea8e8edbbd3dd" ||
        this.currentAccId === "647de0ddfdf35443e301ebcf" ||
        this.currentAccId === "647de0f4fdf354294f01ef36" ||
        this.currentAccId === "647de106f88ea8652cbbde82" ||
        this.currentAccId === "647de11ed44b0d4ad326c9d2" ||
        this.currentAccId === "647de131821e83a789e02735" ||
        this.currentAccId === "647de37afdf354c84502809b" ||
        this.currentAccId === "647de399d44b0d36682757c2" ||
        this.currentAccId === "647de3af7c43cdc9d2c25192" ||
        this.currentAccId === "647e12ed2e3fd754b5375ef1" ||
        this.currentAccId === "648c67528f79849e304b2205" ||
        this.currentAccId === "64a5870d3ef4914948a88910" ||
        this.currentAccId === "64a5871e997f109a046f4c4c" ||
        this.currentAccId === "6310a6c603f50d057e202d02" ||
        this.currentAccId === "6310a75f03f50d65af207d68" ||
        this.currentAccId === "63a36faa51d3cd17c1e400a3" ||
        this.currentAccId === "63ab38651b5aadfa16ab87b6" ||
        this.currentAccId === "63efc042b2d31059ad35d66e"
      );
    },
  },
  async created() {
    this.$vs.loading();
    const hasInbox = await this.$http.get(
      `/g/acc/hasInbox/${this.$store.state.acc.current_acc.id}`
    );
    this.hasInbox = hasInbox.data.data;

    this.$vs.loading.close();

    if (this.$router.currentRoute.name === "auto-messages") this.activeTab = 4;
  },
};
</script>

<style lang="scss" scoped>
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
