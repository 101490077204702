<template>
  <div>
    <vs-chip
      transparent
      :color="
        params.data.status === 'Error'
          ? 'danger'
          : params.data.status === 'Treinando'
          ? 'primary'
          : params.data.status === 'Treinado'
          ? 'success'
          : 'dark'
      "
    >
      {{
        params.data.status === "Error"
          ? "ERRO"
          : params.data.status === "Treinando"
          ? "TREINANDO"
          : params.data.status === "Treinado"
          ? "TREINADO"
          : "NÃO TREINADO"
      }}
    </vs-chip>
  </div>
</template>
<script>
export default {
  name: "StatusLabel",
};
</script>
