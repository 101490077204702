<template>
  <div class="flex items-center gap-x-3">
    <vs-button
      v-if="hasInbox && $acl.check('admin')"
      :disabled="userData.status !== 1 || userData.isDisabled"
      icon-pack="feather"
      icon="icon-edit"
      type="flat"
      @click="$emit('toggle-edit')"
      color="warning"
    />

    <vs-dropdown
      vs-custom-content
      vs-trigger-click
      class="dark-btn rounded-md cursor-pointer"
    >
      <vs-button
        icon-pack="feather"
        icon="icon-more-vertical"
        type="flat"
        color="dark"
      />
      <vs-dropdown-menu>
        <vs-dropdown-item
          v-if="$acl.check('admin') && !inviteKey"
          @click="$emit('toggle-disable')"
        >
          {{ userData.isDisabled ? "Ativar" : "Desativar" }}
        </vs-dropdown-item>
        <vs-dropdown-item @click="confirmDeleteRecord()"
          >Excluir</vs-dropdown-item
        >
      </vs-dropdown-menu>
    </vs-dropdown>
    <input
      ref="textLink"
      type="text"
      :value="'https://app.duotalk.io/invitation/' + inviteKey"
      class="hidden"
      v-if="inviteKey"
    />
    <vs-button
      v-if="inviteKey"
      icon-pack="feather"
      icon="icon-link"
      type="flat"
      @click="copyLink()"
    />
  </div>
</template>

<script>
export default {
  name: "CellRendererActions",
  props: {
    userData: {
      type: Object,
      required: false,
    },
  },
  computed: {
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    inviteKey() {
      return this.userData.inviteKey;
    },
    users: {
      get() {
        return this.$store.state.acc.current_acc_users;
      },
      set(val) {
        this.$store.commit("acc/SET_CURRENT_ACC_USERS", val);
      },
    },
  },
  methods: {
    copyLink() {
      let copyText = this.$refs.textLink;

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      this.$vs.notify({
        title: this.$t("Success"),
        text: this.$t("CopiedToClipboard"),
        color: "success",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-check-circle",
        time: 4000,
      });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmRemoveUserAccess"),
        text: this.$t("ConfirmRemoveUserAccessMessage", [
          `${this.userData.name} ${this.userData.email}`,
        ]),
        accept: this.deleteRecord,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    deleteRecord() {
      /* UnComment below lines for enabling true flow if deleting user */
      this.$store
        .dispatch("acc/removeAccAccess", {
          id: this.userData._id,
          acc: this.$store.state.acc.current_acc.id,
        })
        .then(() => {
          this.showDeleteSuccess();
        })
        .catch((error) => {
          if (error.request != null)
            if (typeof error.request.response !== "undefined")
              error.message =
                JSON.parse(error.request.response).data != null
                  ? Array.isArray(JSON.parse(error.request.response).data)
                    ? JSON.parse(error.request.response).data[0].msg
                    : ""
                  : JSON.parse(error.request.response).message;
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message || this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    deleteUserStore(id) {
      // Create a copy of the users array
      const updatedUsers = this.users.filter((user) => user._id !== id);

      // Update the users array in the store
      this.users = updatedUsers; // Trigger the setter of the computed property
    },
    showDeleteSuccess() {
      this.deleteUserStore(this.userData._id);
      this.$vs.notify({
        color: "success",
        title: this.$t("Confirm"),
        text: this.$t("User") + " " + this.$t("DeletedSuccessfuly"),
        position: "top-right",
        time: 4000,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dark-btn {
  transition: all linear 0.2s;
  &:hover {
    background: rgba(30, 30, 30, 0.08) !important;
  }
}
</style>
