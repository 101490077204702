<template>
  <div v-if="dataLoaded">
    <vs-row>
      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("SignOperator") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="confirmActivateRecord('signOperator', $t('SignOperator'))"
        ></div>
        <vs-switch
          color="success"
          v-model="signOperator"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col class="flex" vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("AutomaticConversationDistribution") }}</p>
        <vs-button
          v-if="operatorQueue"
          type="flat"
          class="ml-2 hover:text-white"
          size="small"
          icon="icon-edit"
          icon-pack="feather"
          color="black"
          @click="operatorQueuePopup = true"
        />
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="
            confirmActivateRecord(
              'operatorQueue',
              $t('AutomaticConversationDistribution')
            )
          "
        ></div>
        <vs-switch
          color="success"
          v-model="operatorQueue"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("SmartSecondContact") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="
            confirmActivateRecord(
              'smartSecondContact',
              $t('SmartSecondContact')
            )
          "
        ></div>
        <vs-switch
          color="success"
          v-model="smartSecondContact"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("BlockContactCreation") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="
            confirmActivateRecord(
              'blockCreateConversationOperator',
              $t('BlockContactCreation')
            )
          "
        ></div>
        <vs-switch
          color="success"
          v-model="blockCreateConversationOperator"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("BlockContactTab") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="
            confirmActivateRecord('blockContactTab', $t('BlockContactTab'))
          "
        ></div>
        <vs-switch
          color="success"
          v-model="blockContactTab"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("BlockWaitingServiceTab") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="
            confirmActivateRecord(
              'blockWaitingServiceTab',
              $t('BlockWaitingServiceTab')
            )
          "
        ></div>
        <vs-switch
          color="success"
          v-model="blockWaitingServiceTab"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12" vs-type="flex" vs-align="center">
        <p class="text-base">{{ $t("Downtime") }}</p>
        <vs-tooltip
          class="ml-2"
          text="O termo 'tempo de inatividade' descreve o intervalo sem atividade em uma conversa. Caso nenhum dos participantes interaja por um período específico de dias, a conversa será automaticamente encerrada e registrado como 'INATIVIDADE'"
        >
          <feather-icon icon="InfoIcon" svgClasses="mt-2 h-5 w-5" />
        </vs-tooltip>
      </vs-col>

      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <p class="text-base">{{ $t("MinMaxDays") }}</p>
        <vs-input-number
          v-model.lazy="inactivityTime"
          min="1"
          max="90"
          class="float-left"
          id="dayInput"
        />
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12" vs-type="flex" vs-align="center">
        <p class="text-base">{{ $t("InboxLoadSize") }}</p>
        <vs-tooltip class="ml-2" :text="$t('InboxLoadSizeInfo')">
          <feather-icon icon="InfoIcon" svgClasses="mt-2 h-5 w-5" />
        </vs-tooltip>
      </vs-col>

      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <p class="text-base">{{ $t("InboxLoadMinMax") }}</p>
        <div class="flex">
          <vs-input-number
            v-model.lazy="loadSize"
            min="20"
            max="500"
            class="float-left"
            id="dayInput"
          />
        </div>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("ReasonsForClosure") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div class="xl:w-1/2 w-full">
          <template v-if="closingConversationReasons">
            <CustomTag
              v-for="(item, index) in closingConversationReasons"
              :key="index"
              class="mt-2"
              :label="item.name"
              color="grey"
              edit
              :remove="item.canDelete"
              :lockIcon="!item.canDelete"
              @edit="editReason(item)"
              @remove="removeReason(item)"
            />
          </template>

          <vs-button
            class="mt-4 float-right"
            icon="icon-plus"
            icon-pack="feather"
            @click="addReasonPopUp = true"
            >Adicionar novo</vs-button
          >
        </div>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("ServiceAlerts") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div class="xl:w-1/2 w-full">
          <CustomTag
            :label="'Até <b>' + flagGreen + '</b> ' + $t('Hours')"
            color="success"
            edit
            @edit="openEditFlagPopUp('Verde')"
          />
          <CustomTag
            class="mt-2"
            :label="
              'Todas as conversas que estiverem entre <b>' +
              flagGreen +
              '</b> ' +
              $t('Hours') +
              ' e <b>' +
              flagRed +
              '</b> ' +
              $t('Hours') +
              '.'
            "
            color="warning"
            lockIcon
          />
          <CustomTag
            class="mt-2"
            :label="'Após <b>' + flagRed + '</b> ' + $t('Hours')"
            color="danger"
            edit
            @edit="openEditFlagPopUp('Vermelho')"
          />
        </div>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">Fuso horário da conta</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <vs-select v-model="timeZone" autocomplete width="300px">
          <vs-select-item
            v-for="(item, index) in timezones"
            :key="index"
            :value="item"
            :text="item"
          />
        </vs-select>
      </vs-col>

      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <div class="flex items-center">
          <p class="text-base">Horários de atendimento</p>
          <vs-tooltip
            class="ml-2"
            text="Este horário será utilizado para cálculo do tempo médio de resposta dos seus atendimentos"
          >
            <feather-icon icon="InfoIcon" svgClasses="mt-2 h-5 w-5" />
          </vs-tooltip>
        </div>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div class="xl:w-1/2 w-full">
          <CustomTag
            v-for="(item, index) in listOfHoursAndDays"
            :key="index"
            class="mb-2"
            :label="
              item.weekDay +
              ' - ' +
              (item.startHour.length > 0 ? item.startHour + 'h' : '') +
              (item.startHour.length > 0 && item.endHour.length > 0
                ? ' até '
                : 'Fechado') +
              (item.endHour.length > 0 ? item.endHour + 'h' : '')
            "
            color="grey"
            edit
            @edit="openModalEditWeekDay(item)"
          />
        </div>
      </vs-col>
      <vs-col vs-w="12" class="my-5">
        <vs-divider />
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" vs-xs="12">
        <p class="text-base">{{ $t("RestrictOperator") }}</p>
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12" class="sm:mt-4 md:mt-0">
        <div
          style="
            cursor: pointer;
            width: 42% !important;
            height: 24px !important;
            position: absolute !important;
            z-index: 5500;
          "
          @click="
            confirmActivateRecord('restrictOperator', $t('RestrictOperator'))
          "
        ></div>
        <vs-switch
          color="success"
          v-model="restrictOperator"
          @click="loadFirstTime = false"
        >
          <span slot="on">{{ $t("Active") }}</span>
          <span slot="off">{{ $t("Inactive") }}</span>
        </vs-switch>
      </vs-col>
    </vs-row>

    <vs-popup title="Tempo para distribuição" :active.sync="operatorQueuePopup">
      <vs-row>
        <v-col class="flex items-center" vs-w="12">
          <vs-input-number
            v-model.lazy="operatorQueueTime"
            :min="5"
            :max="60"
          />
          <p class="ml-2">Minutos</p>
        </v-col>
        <vs-col vs-w="12" class="mt-4">
          <p>
            Este período se aplica somente para conversas em que o fluxo do bot
            não foi finalizado pelo contato. Caso ele complete todo o fluxo, a
            conversa segue
            <span class="font-bold">imediatamente</span>
            para a distribuição
          </p>
        </vs-col>
        <vs-col vs-w="12" class="mt-4 flex justify-end">
          <vs-button color="success" @click="updateInboxOperatorQueueTime()">
            Salvar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <!-- Editar Horário de Atendimento -->
    <vs-popup
      :title="'Editar horário de atendimento de ' + editWeekDay.weekDay"
      :active.sync="modalEditWeekDay"
    >
      <vs-row>
        <vs-col>
          <h5 class="text-center">Selecione os dias e horários</h5>
        </vs-col>

        <vs-col
          vs-w="8"
          vs-offset="2"
          vs-type="flex"
          vs-justify="space-between"
          class="mt-3"
        >
          <vs-tooltip
            v-for="(item, index) in listOfHoursAndDays"
            :key="index"
            :text="item.weekDay"
          >
            <button
              class="vs-component vs-button vs-button-primary vs-radius justify-center flex items-center"
              :class="{
                'vs-button-filled': listDayToEdit.includes(item.weekDay),
                'vs-button-border': !listDayToEdit.includes(item.weekDay),
              }"
              style="width: 37px; height: 37px"
              @click="handleListDayToEdit(item.weekDay)"
              :disabled="item.weekDay === editWeekDay.weekDay"
            >
              {{ item.weekDay.substring(0, 1) }}
            </button>
          </vs-tooltip>
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="mt-4">
          <vs-checkbox v-model="editWeekDay.closed">Fechado</vs-checkbox>
        </vs-col>
        <vs-col
          v-if="!editWeekDay.closed"
          vs-w="8"
          vs-offset="2"
          vs-type="flex"
          vs-align="center"
          vs-justify="center"
          class="mt-4"
        >
          <vs-input type="time" v-model="editWeekDay.startHour" />
          <span class="mx-3">até</span>
          <vs-input type="time" v-model="editWeekDay.endHour" />
        </vs-col>
        <vs-col vs-w="12" vs-type="flex" vs-justify="center" class="mt-4">
          <vs-button
            color="success"
            type="filled"
            icon-pack="feather"
            icon="icon-save"
            :disabled="
              !editWeekDay.closed &&
              (!editWeekDay.startHour || !editWeekDay.endHour)
                ? true
                : false
            "
            @click="saveOpeningHours(editWeekDay, listDayToEdit)"
          >
            {{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <!--  Editar Status  -->
    <vs-popup
      :title="$t('EditStatus') + ' - ' + currentEditingFlag"
      :active.sync="flagsPopUp"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center">
          <vs-input
            type="time"
            v-model="flagsPopUpInput"
            :danger="!canSaveStatus"
            :danger-text="
              'Valor ' +
              (currentEditingFlag === 'Verde' ? 'máximo' : 'mínimo') +
              ' não permitido!'
            "
          />
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center">
          <vs-button
            class="mt-5"
            color="success"
            type="filled"
            @click="confirmEditFlag()"
            :disabled="!canSaveStatus"
            icon-pack="feather"
            icon="icon-save"
            >{{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <!--  Adicionar novo motivo de encerramento  -->
    <vs-popup :title="$t('AddNewReason')" :active.sync="addReasonPopUp">
      <vs-row>
        <vs-col class="relative" vs-type="flex" vs-align="center">
          <vs-input
            :label-placeholder="$t('Name')"
            v-model="addClosingConversationReasonsInput"
            class="w-full"
            maxlength="20"
            :description-text="
              !descriptionInputReason ? $t('ReasonNameCannotBeEmpty') : ''
            "
            :danger="descriptionInputReason"
            :danger-text="$t('ReasonWithThisNameAlreadyExists') + '!'"
          />
          <span class="absolute pr-3 right-0 text-grey text-base">{{
            addClosingConversationReasonsInput.length
          }}</span>
        </vs-col>
        <vs-col v-if="!openToAddSubReason">
          <vs-select
            :label="$t('TypeOfResearch')"
            v-model="searchType"
            class="mt-4"
            width="100%"
          >
            <vs-select-item
              v-for="(item, index) in searchTypeData"
              :value="item.value"
              :text="item.text"
              :key="index"
            />
          </vs-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" class="mt-4">
          <vs-button
            color="success"
            type="filled"
            @click="
              !openToAddSubReason
                ? addNewClosingConversationReason()
                : addNewClosingConversationSubreason()
            "
            :disabled="
              addClosingConversationReasonsInput.length === 0 ||
              descriptionInputReason
            "
            icon-pack="feather"
            icon="icon-save"
            >{{ $t("Save") }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <!--  Editar nome do motivo  -->
    <vs-popup
      :title="$t('EditStatus') + ' - ' + editReasonInput"
      :active.sync="editReasonPopUp"
    >
      <vs-row>
        <vs-col class="relative" vs-type="flex" vs-align="center">
          <vs-input
            :label-placeholder="$t('name')"
            v-model="currentEditingReason.name"
            class="w-full"
            maxlength="20"
            :description-text="$t('ReasonNameCannotBeEmpty')"
            :disabled="!currentEditingReason.canDelete"
          />
          <span class="absolute pr-3 right-0 text-grey text-base">{{
            editReasonInput.length
          }}</span>
        </vs-col>
        <vs-col
          v-if="
            currentEditingReason.subReasons &&
            currentEditingReason.subReasons.length > 0
          "
          class="mt-4"
        >
          <CustomTag
            v-for="(item, index) in currentEditingReason.subReasons"
            :class="{ 'mt-2': index >= 1 }"
            :key="index"
            :label="item.name"
            color="grey"
            :edit="item.canDelete"
            :remove="item.canDelete"
            :lockIcon="!item.canDelete"
            @edit="editSubReason(item)"
            @remove="removeClosingConversationSubReason(item)"
          />
        </vs-col>
        <vs-col v-if="!editReasonsListPopUp">
          <vs-select
            :label="$t('TypeOfResearch')"
            v-model="currentEditingReason.typeSearch"
            class="mt-4"
            width="100%"
          >
            <vs-select-item
              v-for="(item, index) in searchTypeData"
              :value="item.value"
              :text="item.text"
              :key="index"
            />
          </vs-select>
        </vs-col>

        <vs-col class="mt-4" vs-w="5">
          <vs-tooltip
            :text="$t('NumberOfReasonsReached')"
            :active="
              (currentEditingReason.id === 1 && maxSubReason.saleMade >= 3) ||
              (currentEditingReason.id === 2 && maxSubReason.lostBusiness >= 8)
            "
          >
            <vs-button
              v-if="
                currentEditingReason.id === 1 || currentEditingReason.id === 2
              "
              icon="icon-plus"
              icon-pack="feather"
              @click="addSubReason"
              class="w-full"
              :disabled="
                (currentEditingReason.id === 1 && maxSubReason.saleMade >= 3) ||
                (currentEditingReason.id === 2 &&
                  maxSubReason.lostBusiness >= 8)
              "
              >{{ $t("AddNew") }}</vs-button
            >
          </vs-tooltip>
        </vs-col>
        <vs-col class="mt-4" vs-w="5" vs-offset="2">
          <vs-button
            color="success"
            icon="icon-save"
            icon-pack="feather"
            class="w-full"
            @click="updateReason(currentEditingReason)"
            >{{ $t("Save") }}</vs-button
          >
        </vs-col>
      </vs-row>

      <!--  Editar sub-motivo  -->
      <vs-popup
        title="Adicionar novo motivo"
        :active.sync="editReasonsListPopUp"
      >
        <vs-row>
          <vs-col class="relative" vs-type="flex" vs-align="center">
            <vs-input
              label-placeholder="Nome"
              v-model="subReasonNameInput"
              class="w-full"
              maxlength="20"
              :description-text="
                !descriptionInputReason
                  ? 'O nome do motivo não pode estar vazio'
                  : ''
              "
              :danger="descriptionInputReason"
              danger-text="Um motivo com este nome já existe!"
            />
            <span
              class="absolute pr-3 right-0 text-grey text-base"
              :class="{ descriptionInputReason: 'pt-4' }"
              >{{ subReasonNameInput.length }}</span
            >
          </vs-col>
          <vs-col class="mt-4">
            <vs-button
              class="w-full"
              color="success"
              type="filled"
              @click="updateSubReason(subReasonNameInput)"
              :disabled="
                subReasonNameInput.length === 0 || descriptionInputReason
              "
              icon-pack="feather"
              icon="icon-save"
              >{{ $t("Save") }}</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
    </vs-popup>

    <!--  Remove motivo  -->
    <vs-popup :title="$t('RemoveReason')" :active.sync="removeReasonPopUp">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            {{ $t("AreYouSureWantRemoveReason") }} - {{ removeResonItem.name }}
          </h5>
        </vs-col>
        <vs-col class="mt-4" vs-type="flex" vs-justify="center">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            @click="removeClosingConversationReason(removeResonItem)"
            >{{ $t("Remove") }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import CustomTag from "@/components/CustomTag.vue";
import moment from "moment-timezone";

export default {
  name: "InboxGeneralSettings",
  components: {
    CustomTag,
  },
  data() {
    return {
      listOfHoursAndDays: [
        {
          weekDay: "Segunda",
          startHour: "08:00",
          endHour: "18:00",
          closed: false,
        },
        {
          weekDay: "Terça",
          startHour: "08:00",
          endHour: "18:00",
          closed: false,
        },
        {
          weekDay: "Quarta",
          startHour: "08:00",
          endHour: "18:00",
          closed: false,
        },
        {
          weekDay: "Quinta",
          startHour: "08:00",
          endHour: "18:00",
          closed: false,
        },
        {
          weekDay: "Sexta",
          startHour: "08:00",
          endHour: "18:00",
          closed: false,
        },
        {
          weekDay: "Sábado",
          startHour: "",
          endHour: "",
          closed: true,
        },
        {
          weekDay: "Domingo",
          startHour: "",
          endHour: "",
          closed: true,
        },
      ],
      listDayToEdit: [],
      editWeekDay: {},
      modalEditWeekDay: false,
      timezones: moment.tz.names(),
      timeZone: this.$store.state.acc.current_acc.timeZone,
      timerUpdateInbox: false,
      flagGreen: "01:30",
      flagRed: "02:30",
      flagsPopUp: false,
      currentEditingFlag: "",
      flagsPopUpInput: "",
      closingConversationReasons: false,
      inboxData: null,
      salesSuccessReasons: [],
      salesFailureReasons: [],
      addClosingConversationReasonsInput: "",
      addReasonPopUp: false,
      descriptionInputReason: false,
      currentEditingReasonList: "",
      editReasonsListPopUp: false,
      currentEditingReason: "",
      temporaryArray: [],
      editReasonInput: "",
      editReasonPopUp: false,
      removeReasonPopUp: false,
      removeResonItem: "",
      searchType: null,
      addEditReasonsListPopUp: false,
      searchTypeData: [
        {
          text: "Nenhum",
          value: "Nenhum",
        },
        {
          text: "CSAT",
          value: "CSAT",
        },
        {
          text: "NPS",
          value: "NPS",
        },
      ],
      addEditReasonInput: "",
      openToAddSubReason: false,
      currentSubReason: "",
      subReasonNameInput: "",
      maxSubReason: {
        saleMade: 0,
        lostBusiness: 0,
      },
      loadSize: 20,
      signOperator: null,
      smartSecondContact: null,
      operatorQueue: null,
      operatorQueuePopup: false,
      operatorQueueTime: 5,
      inactivityTime: null,
      canSaveStatus: false,
      loadFirstTime: true,
      blockCreateConversationOperator: true,
      blockContactTab: true,
      blockWaitingServiceTab: true,
      dataLoaded: false,
      restrictOperator: true
    };
  },
  computed: {
    accId() {
      return this.$route.params.acc
        ? this.$route.params.acc
        : this.$store.state.acc.current_acc.id;
    },
  },
  methods: {
    async getInboxConfig() {
      this.$vs.loading();
      try {
        const { data } = await this.$http.get(
          `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        );
        if (data.data) {
          this.inboxData = data.data.inbox;

          this.operatorQueueTime = this.inboxData.operatorQueueTime || 5;

          this.loadSize = this.inboxData.loadSize || 20;
          this.signOperator = !!this.inboxData.signOperator;
          this.smartSecondContact = !!this.inboxData.smartFirstMessage;
          this.operatorQueue = !!this.inboxData.operatorQueue;
          this.blockCreateConversationOperator =
            !!this.inboxData.blockCreateConversationOperator;
          this.blockContactTab = !!this.inboxData.blockContactTab;
          this.blockWaitingServiceTab = !!this.inboxData.blockWaitingServiceTab;
          this.restrictOperator = !!this.inboxData.restrictOperator;
          this.inactivityTime = this.inboxData.inactivityTime || 7;

          this.closingConversationReasons =
            data.data.inbox.closingConversationReasons;

          if (this.inboxData.inboxWarningFlags) {
            this.flagRed =
              this.inboxData.inboxWarningFlags.red.hour +
              ":" +
              this.inboxData.inboxWarningFlags.red.minute;
            this.flagGreen =
              this.inboxData.inboxWarningFlags.green.hour +
              ":" +
              this.inboxData.inboxWarningFlags.green.minute;
          }
          const attendanceHours = await this.$http.get(
            "/g/attendance-hours/" + this.$store.state.acc.current_acc.id
          );
          this.mapAttendanceHours(attendanceHours.data.data);

          this.loadFirstTime = true;
          this.dataLoaded = true;
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        }
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    mapAttendanceHours(items) {
      if (!items) return;
      for (let i = 0; i < 7; i++) {
        const item = items[(i + 1) % 7][0];
        this.listOfHoursAndDays[i].startHour = item ? item.start : "";
        this.listOfHoursAndDays[i].endHour = item ? item.end : "";
      }
    },
    inboxWarningFlags() {
      return {
        green: {
          hour: this.flagGreen.substring(0, 2).replace(/\D/g, ""),
          minute: parseInt(this.flagGreen.substring(3), 10)
            ? this.flagGreen.substring(3).replace(/\D/g, "")
            : "00",
        },
        red: {
          hour: this.flagRed.substring(0, 2).replace(/\D/g, ""),
          minute: parseInt(this.flagRed.substring(3), 10)
            ? this.flagRed.substring(3).replace(/\D/g, "")
            : "00",
        },
      };
    },
    openEditFlagPopUp(flag) {
      this.currentEditingFlag = flag;
      if (flag === "Verde") {
        this.flagsPopUpInput = this.flagGreen;
      } else {
        this.flagsPopUpInput = this.flagRed;
      }
      this.flagsPopUp = true;
    },
    async confirmEditFlag() {
      if (this.currentEditingFlag === "Verde") {
        this.flagGreen = this.flagsPopUpInput;
      } else {
        this.flagRed = this.flagsPopUpInput;
      }
      this.flagsPopUp = false;
      await this.updateInbox("Alterar Alertas de Atendimento");
      this.flagsPopUpInput = "";
    },
    confirmActivateRecord(type, titleRef) {
      if (this[type]) {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: "Desativar " + titleRef.toLowerCase(),
          text: this.$t("ConfirmInactivate"),
          accept: () => this.activateRecord(type, false),
          acceptText: this.$t("Inactivate"),
          cancelText: this.$t("Cancel"),
        });
      } else {
        this.$vs.dialog({
          type: "confirm",
          color: "success",
          title: "Ativar " + titleRef.toLowerCase(),
          text: this.$t("ConfirmActivate"),
          accept: () => this.activateRecord(type, true),
          acceptText: this.$t("Activate"),
          cancelText: this.$t("Cancel"),
        });
      }
    },
    async activateRecord(type, status) {
      this[type] = status;
      await this.updateInbox(`${status ? "Ativar" : "Desativar"} `);
    },
    confirmEdit() {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: this.$t("Edit"),
        text: this.$t("ConfirmEdit"),
        accept: this.updateInbox,
        acceptText: this.$t("Edit"),
        cancelText: this.$t("Cancel"),
      });
    },
    async updateInboxOperatorQueueTime() {
      this.operatorQueuePopup = false;
      await this.updateInbox(
        "Editando o tempo de espera da fila de distribuição automática"
      );
    },
    async updateInbox(event) {
      window.analytics.track(
        event,
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      try {
        const res = await this.$http.put(
          "/p/chat/inbox/config",
          {
            signOperator: this.signOperator,
            operatorQueue: this.operatorQueue,
            operatorQueueTime: this.operatorQueueTime,
            inactivityTime: this.inactivityTime,
            closingConversationReasons: [...this.closingConversationReasons],
            inboxWarningFlags: this.inboxWarningFlags(),
            smartFirstMessage: !!this.smartSecondContact,
            timeZone: this.timeZone,
            blockCreateConversationOperator:
              this.blockCreateConversationOperator,
            blockContactTab: this.blockContactTab,
            blockWaitingServiceTab: this.blockWaitingServiceTab,
            loadSize: this.loadSize,
            restrictOperator: this.restrictOperator
          },
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        );
        if (res.status === 200) {
          this.$vs.notify({
            title: this.$t("Success"),
            text: "As configurações foram atualizadas",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        }
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-danger-circle",
          color: "danger",
          position: "top-right",
        });
      }

      await this.getInboxConfig();
    },
    async addNewClosingConversationReason() {
      if (
        this.addClosingConversationReasonsInput.length > 0 &&
        !this.closingConversationReasons.includes(
          this.addClosingConversationReasonsInput
        )
      ) {
        this.closingConversationReasons.push({
          name: this.addClosingConversationReasonsInput,
          typeSearch: this.searchType,
          canDelete: true,
          subReasons: [],
        });
        this.addClosingConversationReasonsInput = "";
        this.addReasonPopUp = false;
      }
      await this.updateInbox("Novo Motivo de Encerramento");
    },
    async addNewClosingConversationSubreason() {
      this.currentEditingReason.subReasons.push({
        name: this.addClosingConversationReasonsInput,
        canDelete: true,
      });
      this.addClosingConversationReasonsInput = "";
      this.addReasonPopUp = false;
      await this.updateInbox("Novo Sub Motivo de Encerramento");
    },
    editReason(item) {
      this.currentEditingReason = item;
      this.editReasonInput = item.name;
      this.editReasonPopUp = true;
    },
    async updateReason(item) {
      this.closingConversationReasons[
        this.closingConversationReasons.indexOf(item)
      ] = item;
      this.editReasonPopUp = false;
      await this.updateInbox("Editando Motivo de Encerramento");
    },
    editSubReason(item) {
      this.editReasonsListPopUp = true;
      this.subReasonNameInput = item.name;
      this.currentSubReason = item;
    },
    updateSubReason(item) {
      this.currentEditingReason.subReasons[
        this.currentEditingReason.subReasons.indexOf(this.currentSubReason)
      ].name = item;
      this.subReasonNameInput = "";
      this.this.currentSubReason = "";
      this.editReasonsListPopUp = false;
    },
    removeReason(item) {
      this.removeResonItem = item;
      this.removeReasonPopUp = true;
    },
    async removeClosingConversationReason(item) {
      this.closingConversationReasons.splice(
        this.closingConversationReasons.indexOf(item),
        1
      );

      this.removeReasonPopUp = false;
      await this.updateInbox("Removendo Motivo de Encerramento");
      this.removeResonItem = "";
    },
    async removeClosingConversationSubReason(item) {
      this.currentEditingReason.subReasons.splice(
        this.currentEditingReason.subReasons.indexOf(item),
        1
      );
    },
    addSubReason() {
      this.openToAddSubReason = true;
      this.addReasonPopUp = true;
    },
    addWeekDay() {
      this.listOfHoursAndDays.push({
        weekDays: [],
        startHour: "",
        endHour: "",
      });
    },
    removeWeekDay(index) {
      this.listOfHoursAndDays.splice(index, 1);
    },
    openModalEditWeekDay(item) {
      this.modalEditWeekDay = true;
      this.editWeekDay = item;
      this.listDayToEdit.push(item.weekDay);
    },
    handleListDayToEdit(item) {
      if (this.listDayToEdit.includes(item)) {
        this.listDayToEdit.splice(
          this.listDayToEdit.findIndex((x) => x === item),
          1
        );
      } else {
        this.listDayToEdit.push(item);
      }
    },
    async saveOpeningHours(item, weekDaysToEdit) {
      window.analytics.track(
        "Alterar Horários de Atendimento",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      try {
        this.modalEditWeekDay = false;

        weekDaysToEdit.forEach((i) => {
          this.listOfHoursAndDays[
            this.listOfHoursAndDays.findIndex((x) => x.weekDay === i)
          ].startHour = item.closed ? "" : item.startHour;
          this.listOfHoursAndDays[
            this.listOfHoursAndDays.findIndex((x) => x.weekDay === i)
          ].endHour = item.closed ? "" : item.endHour;
          this.listOfHoursAndDays[
            this.listOfHoursAndDays.findIndex((x) => x.weekDay === i)
          ].closed = item.closed;
        });

        const response = await this.$http.post("/g/attendance-hours/upsert", {
          acc: this.$store.state.acc.current_acc.id,
          0: [
            {
              start: this.listOfHoursAndDays[6].startHour,
              end: this.listOfHoursAndDays[6].endHour,
            },
          ],
          1: [
            {
              start: this.listOfHoursAndDays[0].startHour,
              end: this.listOfHoursAndDays[0].endHour,
            },
          ],
          2: [
            {
              start: this.listOfHoursAndDays[1].startHour,
              end: this.listOfHoursAndDays[1].endHour,
            },
          ],
          3: [
            {
              start: this.listOfHoursAndDays[2].startHour,
              end: this.listOfHoursAndDays[2].endHour,
            },
          ],
          4: [
            {
              start: this.listOfHoursAndDays[3].startHour,
              end: this.listOfHoursAndDays[3].endHour,
            },
          ],
          5: [
            {
              start: this.listOfHoursAndDays[4].startHour,
              end: this.listOfHoursAndDays[4].endHour,
            },
          ],
          6: [
            {
              start: this.listOfHoursAndDays[5].startHour,
              end: this.listOfHoursAndDays[5].endHour,
            },
          ],
        });

        if (response.status === 200) {
          this.$vs.notify({
            title: this.$t("Success"),
            text: "As configurações foram atualizadas",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        }
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async updateTimeZone(timezone) {
      window.analytics.track(
        "Alterar Fuso Horário Conta",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      await this.$store
        .dispatch("acc/updateAccTimeZone", {
          id: this.$store.state.acc.current_acc.id,
          timezone: timezone,
        })
        .then(() => {
          this.$vs.notify({
            title: this.$t("Success"),
            text: "As configurações foram atualizadas",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 4000,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
  },
  mounted() {
    if (document.getElementById("dayInput")) {
      document.getElementById("dayInput").style.minWidth = "30px";
      document.getElementById("dayInput").after(this.$t("Days"));
    }
  },
  async created() {
    await this.getInboxConfig();
  },
  watch: {
    addClosingConversationReasonsInput() {
      if (this.closingConversationReasons.length > 0) {
        if (!this.openToAddSubReason) {
          this.closingConversationReasons.forEach((item) => {
            this.descriptionInputReason =
              item.name === this.addClosingConversationReasonsInput;
          });
        } else {
          this.currentEditingReason.subReasons.forEach((item) => {
            this.descriptionInputReason =
              item.name === this.addClosingConversationReasonsInput;
          });
        }
      }
    },
    addEditReasonInput() {
      this.descriptionInputReason = !!(
        this.temporaryArray.length > 0 &&
        this.temporaryArray.includes(this.addEditReasonInput)
      );
    },
    subReasonNameInput() {
      this.currentEditingReason.subReasons.forEach((item) => {
        this.descriptionInputReason = item.name === this.subReasonNameInput;
      });
    },
    "currentEditingReason.subReasons": function (val) {
      if (this.currentEditingReason.id === 1) {
        this.maxSubReason.saleMade = Object.keys(val).length;
      }
      if (this.currentEditingReason.id === 2) {
        this.maxSubReason.lostBusiness = Object.keys(val).length;
      }
    },
    flagsPopUpInput(val) {
      const currentHour = parseInt(val.substring(0, 2));
      const currentMinutes = parseInt(val.substring(3));

      if (this.currentEditingFlag === "Verde") {
        const redHour = parseInt(this.flagRed.substring(0, 2));
        const redMinutes = parseInt(this.flagRed.substring(3));

        if (redHour - currentHour >= 1) {
          this.canSaveStatus = true;
        } else this.canSaveStatus = redMinutes - currentMinutes > 0;
      } else {
        const greenHour = parseInt(this.flagGreen.substring(0, 2));
        const greenMinutes = parseInt(this.flagGreen.substring(3));

        if (currentHour - greenHour >= 1) {
          this.canSaveStatus = true;
        } else this.canSaveStatus = greenMinutes - currentMinutes < 0;
      }
    },
    flagsPopUp(val) {
      if (val === false) this.currentEditingFlag = "";
    },
    editReasonPopUp(val) {
      if (!val) {
        this.currentEditingReason.name = this.editReasonInput;
      }
    },
    async signOperator() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.signOperator ? "Ativar" : "Desativar"
            } Motivo de Encerramento`
          )
        : null;
    },
    async operatorQueue() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.operatorQueue ? "Ativar" : "Desativar"
            } Distribuição de Conversa`
          )
        : null;
    },
    async smartSecondContact() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.smartSecondContact ? "Ativar" : "Desativar"
            } Contato Inteligente`
          )
        : null;
    },
    async blockCreateConversationOperator() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.blockCreateConversationOperator ? "Ativar" : "Desativar"
            } Bloquear Conversa Ativa Operador`
          )
        : null;
    },
    async blockContactTab() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.blockContactTab ? "Ativar" : "Desativar"
            } Bloquear Aba Contato`
          )
        : null;
    },
    async blockWaitingServiceTab() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.blockWaitingServiceTab ? "Ativar" : "Desativar"
            } Bloquear Fila Aguardando atendimento`
          )
        : null;
    },
    async restrictOperator() {
      !this.loadFirstTime
        ? await this.updateInbox(
            `${
              this.restrictOperator ? "Ativar" : "Desativar"
            } Restringir Acesso do Operador`
          )
        : null;
    },

    async loadSize() {
      const thisIns = this;
      if (this.timerUpdateInbox) {
        clearTimeout(this.timerUpdateInbox);
        this.timerUpdateInbox = null;
      }
      this.timerUpdateInbox = setTimeout(async () => {
        !thisIns.loadFirstTime
          ? await thisIns.updateInbox("Alterar Quantidade Carregamento Inbox")
          : (thisIns.loadFirstTime = false);
      }, 800);
    },
    async inactivityTime() {
      const thisIns = this;
      if (this.timerUpdateInbox) {
        clearTimeout(this.timerUpdateInbox);
        this.timerUpdateInbox = null;
      }
      this.timerUpdateInbox = setTimeout(async () => {
        !thisIns.loadFirstTime
          ? await thisIns.updateInbox("Alterar Tempo de Inatividade")
          : (thisIns.loadFirstTime = false);
      }, 800);
    },
    modalEditWeekDay(val) {
      if (!val) {
        this.listDayToEdit = [];
        this.editWeekDay = {};
      }
    },
    async timeZone(val) {
      await this.updateTimeZone(val);
    },
  },
};
</script>
<style>
.endHour .vs-input--input.normal,
.startHour .vs-input--input.normal {
  padding: 8px !important;
}
</style>
