export const LANGUAGES = [
  { label: "Albanian (sq)", value: "sq" },
  { label: "Arabic (ar)", value: "ar" },
  { label: "Azerbaijani (az)", value: "az" },
  { label: "Bengali (bn)", value: "bn" },
  { label: "Bulgarian (bg)", value: "bg" },
  { label: "Catalan (ca)", value: "ca" },
  { label: "Chinese (CHN)", value: "zh_CN" },
  { label: "Chinese (HKG)", value: "zh_HK" },
  { label: "Chinese (TAI)", value: "zh_TW" },
  { label: "Croatian (hr)", value: "hr" },
  { label: "Czech (cs)", value: "cs" },
  { label: "Danish (da)", value: "da" },
  { label: "Dutch (nl)", value: "nl" },
  { label: "English (en)", value: "en" },
  { label: "English (UK)", value: "en_GB" },
  { label: "English (US)", value: "en_US" },
  { label: "Estonian (et)", value: "et" },
  { label: "Filipino (fil)", value: "fil" },
  { label: "Finnish (fi)", value: "fi" },
  { label: "French (fr)", value: "fr" },
  { label: "Georgian (ka)", value: "ka" },
  { label: "German (de)", value: "de" },
  { label: "Greek (el)", value: "el" },
  { label: "Gujarati (gu)", value: "gu" },
  { label: "Hausa (ha)", value: "ha" },
  { label: "Hebrew (he)", value: "he" },
  { label: "Hindi (hi)", value: "hi" },
  { label: "Hungarian (hu)", value: "hu" },
  { label: "Indonesian (id)", value: "id" },
  { label: "Irish (ga)", value: "ga" },
  { label: "Italian (it)", value: "it" },
  { label: "Japanese (ja)", value: "ja" },
  { label: "Kannada (kn)", value: "kn" },
  { label: "Kazakh (kk)", value: "kk" },
  { label: "Kinyarwanda (rw_RW)", value: "rw_RW" },
  { label: "Korean (ko)", value: "ko" },
  { label: "Kyrgyz (Kyrgyzstan)", value: "ky_KG" },
  { label: "Lao (lo)", value: "lo" },
  { label: "Latvian (lv)", value: "lv" },
  { label: "Lithuanian (lt)", value: "lt" },
  { label: "Macedonian (mk)", value: "mk" },
  { label: "Malay (ms)", value: "ms" },
  { label: "Malayalam (ml)", value: "ml" },
  { label: "Marathi (mr)", value: "mr" },
  { label: "Norwegian (nb)", value: "nb" },
  { label: "Persian (fa)", value: "fa" },
  { label: "Polish (pl)", value: "pl" },
  { label: "Portugues (pt_BR)", value: "pt_BR" },
  { label: "Portugues (pt_PT)", value: "pt_PT" },
  { label: "Punjabi (pa)", value: "pa" },
  { label: "Romanian (ro)", value: "ro" },
  { label: "Russian (ru)", value: "ru" },
  { label: "Serbian (sr)", value: "sr" },
  { label: "Slovak (sk)", value: "sk" },
  { label: "Slovenian (sl)", value: "sl" },
  { label: "Spanish (es)", value: "es" },
  { label: "Spanish (ARG)", value: "es_AR" },
  { label: "Spanish (SPA)", value: "es_ES" },
  { label: "Spanish (MEX)", value: "es_MX" },
  { label: "Swahili (sw)", value: "sw" },
  { label: "Swedish (sv)", value: "sv" },
  { label: "Tamil (ta)", value: "ta" },
  { label: "Telugu (te)", value: "te" },
  { label: "Thai (th)", value: "th" },
  { label: "Turkish (tr)", value: "tr" },
  { label: "Ukrainian (uk)", value: "uk" },
  { label: "Urdu (ur)", value: "ur" },
  { label: "Uzbek (uz)", value: "uz" },
  { label: "Vietnamese (vi)", value: "vi" },
  { label: "Zulu (zu)", value: "zu" },
];
