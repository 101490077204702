<!-- =========================================================================================
  File Name: QuickMessagesList.vue
  Description: QuickMessagesList List page
========================================================================================== -->

<template>
  <vs-row>
    <vs-col
      vs-w="12"
      vs-type="flex"
      vs-align="center"
      vs-justify="space-between"
    >
      <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
      <vs-input
        icon="icon-search"
        icon-pack="feather"
        v-model="searchQuery"
        @input="updateSearchQuery"
        :placeholder="$t('Search')"
      />
      <vs-button
        icon-pack="feather"
        icon="icon-file-text"
        size="medium"
        color="success"
        @click="popupActive = true"
        >{{ $t("AddQuickMessage") }}</vs-button
      >
    </vs-col>

    <!-- AgGrid Table -->
    <vs-col vs-w="12">
      <ag-grid-vue
        @gridReady="onGridReady"
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="messagesData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="false"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      />
    </vs-col>

    <vs-popup :title="$t('QuickMessages')" v-if="popupActive" :active.sync="popupActive">
      <create-quick-message-popup
        @closeCreatePopup="closeCreatePopup()"
        @getQuickMessages="getQuickMessages()"
        :intentions="intentions"
        :entities="entities"
        :create-quick-messages-options="createQuickMessagesOptions"
        :quick-message-media="quickMessageMedia"
        :quick-message-media-mimetype="quickMessageMediaMimetype"
      />
    </vs-popup>

    <vs-popup title="Template WhatsApp" :active.sync="popupWhatsappActive">
      <vs-row>
        <vs-col vs-w="12">
          <p>
            Submeter template de mensagem para aprovação do Facebook / Whatsapp
          </p>
        </vs-col>
        <vs-col vs-w="12" class="mt-3">
          <vs-select
            v-model="chatFilter"
            width="100%"
            :label="chatOptions[0].label"
          >
            <template v-for="(item, index) in chatOptions">
              <vs-select-item
                v-if="item.value"
                :key="index"
                :value="item.value"
                :text="item.label"
              />
            </template>
          </vs-select>
        </vs-col>
      </vs-row>
      <vs-row v-if="currentChat" class="mt-4">
        <vs-col vs-w="12" v-if="showAllWppTemplates">
          <p class="mt-3">Lista de todos os templates aprovados.</p>
          <CustomAlert
            v-for="(item, index) in allWppTemplates"
            :key="index"
            :title="item.name"
            @action="deleteWppTemplate(item.name)"
            :description="
              item.components[0].type === 'header'
                ? item.components[1].parameters[0].text
                : item.components[0].parameters[0].text
            "
            :color="item.status === 'APPROVED' ? 'success' : 'danger'"
            class="mt-3"
          />
        </vs-col>

        <vs-col vs-w="12" v-else>
          <vs-button @click="showAllWppTemplates = true" icon="dynamic_feed"
            >Verificar templates divergentes</vs-button
          >

          <vs-alert
            title="Status atual"
            active="true"
            :color="!currentWppTemplate.templateStatus ? 'dark' : 'success'"
            class="mt-3"
          >
            {{
              !currentWppTemplate.templateStatus
                ? "Não Submetido"
                : currentWppTemplate.templateStatus
            }}
          </vs-alert>

          <vs-alert
            title="Motivo da negativa"
            :active="currentWppTemplate.rejectedReason"
            color="danger"
            class="mt-3"
            v-if="currentWppTemplate.rejectedReason"
          >
            {{ currentWppTemplate.rejectedReason }}
          </vs-alert>

          <div
            class="mt-4"
            v-if="currentWppTemplate.templateStatus === 'APPROVED'"
          >
            <vs-select v-model="webhookChannel" label="Webhook" width="100%">
              <vs-select-item :value="1" text="RD Station" />
              <vs-select-item :value="2" text="ActiveCampaign" />
              <vs-select-item :value="5" text="Nectar CRM" />
              <vs-select-item :value="6" text="Followize" />
              <vs-select-item :value="3" text="Custom" />
            </vs-select>
            <vs-input
              v-if="webhookChannel === 3"
              class="mt-3 w-full"
              v-model="webhookChannelName"
              placeholder="Custom"
            />
            <div class="grid grid-cols-2 gap-x-4 mt-4">
              <label class="vs-input--label">{{ $t("Intention") }}</label>
              <v-select
                v-model="intentions"
                :options="intentionsOptions"
                multiple
              />
              <label class="vs-input--label">{{ $t("KeyEntities") }}</label>
              <v-select v-model="entities" :options="inboxEntities" multiple />
            </div>
            <div class="w-full mt-4">
              <p class="vs-select--label">Enviar para conversas em aberto?</p>
              <vs-switch v-model="sendToOpenConversations" class="mt-2">
                <span slot="on">{{ $t("Yes") }}</span>
                <span slot="off">{{ $t("No") }}</span>
              </vs-switch>
            </div>
            <p class="mt-4 vs-select--label">URL</p>
            <vs-input
              type="url"
              class="w-full"
              icon-no-border
              icon-pack="feather"
              icon="icon-link"
              v-model="webhookURL"
            />
          </div>

          <div class="mt-4" v-if="!currentWppTemplate.templateStatus">
            <p class="vs-select--label">Template header Image/Video</p>
            <input
              type="file"
              id="imgupload"
              accept=".jpg,.jpeg,.mp4,.3gpp,.png"
              @change="onFileChange"
              class="d-none"
            />
            <vs-button
              color="primary"
              @click="showUploadDialog"
              icon-pack="feather"
              icon="icon-upload"
              class="mt-1"
              >Fazer Upload</vs-button
            >
            <p v-if="headerMediaFileName">
              <small>{{ headerMediaFileName }}</small>
            </p>
          </div>

          <div class="mt-4">
            <p class="vs-select--label">{{ $t("Category") }}</p>
            <div class="d-flex mt-2">
              <vx-tooltip
                v-for="(item, i) in wppApiTemplateCategoryOptions"
                :text="item.label"
                :key="i"
                class="mr-4"
              >
                <vs-radio
                  v-model="currentWppTemplate.templateCategory"
                  :vs-value="item.id"
                  >{{ item.id }}</vs-radio
                >
              </vx-tooltip>
            </div>
          </div>

          <vs-alert
            :active="currentWppTemplate.isModified"
            color="warning"
            class="mt-4"
          >
            O texto do template foi alterado, submeta o template novamente para
            refletir as alterações no WhatsApp
          </vs-alert>
        </vs-col>
        <vs-col
          vs-w="12"
          class="mt-4"
          vs-type="flex"
          :vs-justify="showAllWppTemplates ? 'space-between' : 'flex-end'"
        >
          <vs-button
            v-if="showAllWppTemplates"
            @click="showAllWppTemplates = false"
            icon-pack="feather"
            icon="icon-arrow-left"
            >{{ $t("Back") }}</vs-button
          >
          <vs-button
            icon-pack="feather"
            icon="icon-save"
            @click="submitWhatsappApiTemplate()"
            :disabled="
              submitingWhatsappApiTemplate ||
              !currentWppTemplate.templateCategory ||
              (currentWppTemplate.templateStatus === 'APPROVED' &&
                currentWppTemplate.isModified === false)
            "
          >
            {{ $t("SubmitTemplate") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup v-if="popupEditActive" :title="$t('Edit')" :active.sync="popupEditActive">
      <create-quick-message-popup
        @closeEditPopup="closeEditPopup()"
        @getQuickMessages="getQuickMessages()"
        :qck-msg-id="editid"
        :is-editing="true"
        :edit-id="editid"
        :edit-type="selectedQckMessageType"
        :edit-title="title"
        :edit-message="message"
        :intentions="intentions"
        :entities="entities"
        :edit-message-disable="editMessageDisable"
        :create-quick-messages-options="createQuickMessagesOptions"
        :quick-message-media="quickMessageMedia"
        :quick-message-media-mimetype="quickMessageMediaMimetype"
      />
    </vs-popup>
  </vs-row>
</template>

<script>
import axios from "@/axios";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CustomAlert from "../../../../components/CustomAlert.vue";
import vSelect from "vue-select";
import normalizeString from "@/helpers/normalizeString";
import createQuickMessagePopup from "@/components/duotalk-inbox/createQuickMessagePopup.vue";

export default {
  components: {
    createQuickMessagePopup,
    CustomAlert,
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    cellRendererStatus: CellRendererStatus,
    cellRendererActions: CellRendererActions,
    vSelect,
  },
  data() {
    return {
      // start wpp data
      headerMedia: null,
      headerMediaBase64: null,
      quickMessageMedia: null,
      quickMessageMediaMimetype: "",
      webhookChannel: null,
      webhookChannelName: "",
      submitingWhatsappApiTemplate: false,
      currentQuickMessageId: null,
      showAllWppTemplates: false,
      popupWhatsappActive: false,
      chatFilter: null,
      currentChat: null,
      allWppTemplates: [],
      wppTemplateCategory: null,
      currentWppTemplate: null,
      quickMessage: null,
      editMessageDisable: false,
      templateWithImageHeader: "",
      // end wpp data
      popupEditActive: false,
      inboxID: null,
      editid: null,
      inboxIntentions: [],
      acl: "",
      aclOptions: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: this.$t("Manager"),
          value: "manager",
        },
        {
          label: this.$t("Operator"),
          value: "operator",
        },
      ],
      sendToOpenConversations: "2",
      createQuickMessagesOptions: {
        intentions: [],
        entities: [],
      },
      selectedQckMessageType : 1,
      title: "",
      intentionsOptions: [],
      intentions: [],
      inboxEntities: [],
      entities: [],
      message: "",
      popupActive: false,
      searchQuery: "",
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$t("Title"),
          field: "title",
          filter: true,
          getQuickFilterText: params => {
            return normalizeString(params.value)
          }
        },
        {
          headerName: this.$t("Message"),
          field: "message",
          filter: true,
          getQuickFilterText: params => {
            return normalizeString(params.value)
          }
        },
        {
          headerName: this.$t("Intentions"),
          field: "intentions",
          filter: true,
          valueFormatter: this.replaceEntitiesIntentions,
          getQuickFilterText: params => {
            return normalizeString(params.value[0])
          }
        },
        {
          headerName: this.$t("KeyEntities"),
          field: "entities",
          filter: true,
          valueFormatter: this.replaceEntitiesIntentions,
          getQuickFilterText: params => {
            return normalizeString(params.value[0])
          }
        },
        {
          headerName: this.$t("CreatedBy"),
          field: "user",
          filter: true,
          getQuickFilterText: params => {
            return normalizeString(params.value)
          }
        },
        {
          headerName: this.$t("CardActions"),
          field: "_id",
          cellRenderer: "cellRendererActions",
          maxWidth: 180,
        },
      ],
      inboxQuickMessagesFiltered: [],
    };
  },
  watch: {
    popupActive(val) {
      if (!val) {
        this.title = "";
        this.message = "";
        this.intentions = [];
        this.entities = [];
        this.quickMessageMedia = null;
        this.quickMessageMediaMimetype = "";
        this.$refs.inputMedia.value = "";
      } else {
        window.analytics.track(
          "Nova Mensagem Rápida",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
      }
    },
    popupWhatsappActive(val) {
      if (!val) {
        this.currentChat = null;
        this.chatFilter = null;
        this.showAllWppTemplates = false;
        const uploadElm = document.querySelector("#imgupload");
        if (uploadElm) uploadElm.value = "";
        this.headerMedia = "";
        this.headerMediaBase64 = "";
        this.headerMediaFileName = "";
      }
    },
    chatFilter(val) {
      if (!val) return;

      this.currentChat = this.chatOptions.find((item) => item.value === val);

      if (val) {
        this.getWhatsappApiTemplates();
        this.currentWppTemplate = {
          chat: this.currentChat,
          templateName: "",
          templateID: "",
          templateStatus: "",
          templateCategory: "",
          rejectedReason: null,
          isModified: false,
        };

        this.currentWppTemplate.templateName = `
          tp
          ${this.title}
          "_"
          ${this.currentChat.label}`
          .trim()
          .replace(/\s\s+/g, " ")
          .replace(/ /g, "_")
          .replace(/[^a-z0-9_]/gi, "")
          .toLowerCase();

        if (this.quickMessage.whatsappApiTemplates) {
          const wppTemplate = this.quickMessage.whatsappApiTemplates.find(
            (e) => e.chat === this.currentChat.value
          );
          if (wppTemplate) {
            this.currentWppTemplate = wppTemplate;
          }
        }
      }
    },
  },
  computed: {
    quickMessageMediaUrl() {
      return typeof this.quickMessageMedia === "string"
        ? this.quickMessageMedia
        : URL.createObjectURL(this.quickMessageMedia);
    },
    headerMediaFileName() {
      if (this.headerMedia) return this.headerMedia.name;
      return "";
    },
    webhookURL: {
      get() {
        let query = "";
        if (this.intentions.length > 0 || this.entities.length > 0)
          query += "?queryParams=true";
        if (this.intentions.length > 0 && this.intentions !== "Todos")
          query += "&intention=" + this.intentions;
        if (this.entities.length > 0 && this.entities !== "Todos")
          query += "&entity=" + this.entities;
        if (this.webhookChannel) query += "&provider=" + this.webhookChannel;
        if (this.sendToOpenConversations === "1") query += "&sendToOpen=1";
        if (
          this.webhookChannel === 3 &&
          this.webhookChannelName &&
          this.webhookChannelName.length > 0
        )
          query += "&providerName=" + this.webhookChannelName;
        return encodeURI(
          `https://api.duotalk.io/p/campaign/send/${this.quickMessage._id}/${this.currentWppTemplate.templateID}${query}`
        );
      },
      set() {},
    },
    chatOptions() {
      return [{ label: this.$t("ChooseOneChatbot"), value: "" }].concat(
        this.$store.state.chat.chats
      );
    },
    wppApiTemplateCategoryOptions: function () {
      return [
        { id: "MARKETING", label: this.$t("WppApiTemplateCategory13") },
        { id: "UTILITY", label: this.$t("WppApiTemplateCategory12") },
        { id: "AUTHENTICATION", label: this.$t("WppApiTemplateCategory14") },
      ];
    },
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    messagesData() {
      return this.$store.state.chat.inboxQuickMessages;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    closeCreatePopup() {
      this.popupActive = false;
    },
    closeEditPopup() {
      this.popupEditActive = false;
    },
    async previewFile(e) {
      if (!e.target.files.length) {
        this.quickMessageMedia = null;
        return;
      }

      this.quickMessageMedia = e.target.files[0];

      this.quickMessageMediaMimetype = this.quickMessageMedia.type || "";
    },
    getBase64() {
      const thisIns = this;
      const reader = new FileReader();
      reader.readAsDataURL(this.headerMedia);
      reader.onload = function () {
        thisIns.headerMediaBase64 = reader.result;
      };
      reader.onerror = function (error) {
        thisIns.$vs.notify({
          time: 2500,
          title: thisIns.$t("Error"),
          text: `${thisIns.$t("UnexpectedError")} - ${error}`,
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "danger",
          position: "top-right",
        });
      };
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.headerMedia = files[0];
      this.getBase64();
    },
    showUploadDialog() {
      const uploadElm = document.querySelector("#imgupload");
      if (uploadElm) uploadElm.click();
    },
    openEdit(id) {
      this.editid = id;
      const quickMessage = this.$store.state.chat.inboxQuickMessages.find((i) => i._id === id);
      this.title = quickMessage.title;
      this.message = quickMessage.message;
      switch (quickMessage.type) {
        case "text/media":
          this.selectedQckMessageType = 1;
          break;
        case "audio":
          this.selectedQckMessageType = 2;
          this.quickMessageMedia = quickMessage.audioUrl;
          break;
        default:
          this.selectedQckMessageType = 1;
          break;
      }
      const entities = [];
      if (quickMessage.entities && quickMessage.entities.length) {
        quickMessage.entities.forEach((el) => {
          let label = el;
          if (el === "__all__") label = this.$t("All");
          if (el === "__other__") label = this.$t("Outros");
          entities.push({
            label: label,
            value: el,
          });
        });
      } else entities.push({ label: this.$t("All"), value: "__all__" });
      this.entities = entities;

      const intentions = [];
      if (quickMessage.intentions && quickMessage.intentions.length) {
        quickMessage.intentions.forEach((el) => {
          let label = el;
          if (el === "__all__") label = this.$t("All");
          if (el === "__other__") label = this.$t("Outros");
          intentions.push({
            label: label,
            value: el,
          });
        });
      } else intentions.push({ label: this.$t("All"), value: "__all__" });
      this.intentions = intentions;

      if (quickMessage.header) {
        this.quickMessageMedia = quickMessage.header.url;
        this.quickMessageMediaMimetype = quickMessage.header.mimetype;
      }

      this.editMessageDisable = !!(quickMessage.whatsappApiTemplates && quickMessage.whatsappApiTemplates.length > 0);
      this.popupEditActive = true;
    },
    // start wpp api
    deleteAvailable(id) {
      const wppTemplate = this.inboxQuickMessagesFiltered.find((i) => {
        if (
          i.whatsappApiTemplates &&
          i.whatsappApiTemplates.length > 0 &&
          i.whatsappApiTemplates.find((el) => el.templateID === id)
        )
          return true;
      });
      return !wppTemplate;
    },
    openWppPopup(id) {
      this.currentQuickMessageId = id;
      this.popupWhatsappActive = true;
      const quickMessage = this.inboxQuickMessagesFiltered.find(
        (i) => i._id === this.currentQuickMessageId
      );
      this.quickMessage = quickMessage;
      this.title = quickMessage.title;
      this.message = quickMessage.message;
      this.popupWhatsappActive = true;
    },
    getWhatsappApiTemplates() {
      this.$vs.loading();
      this.templateWithImageHeader = "";
      this.$http({
        method: "get",
        url: `/p/chat/inbox/provider/whatsapp-api/templates/${this.currentChat.value}`,
        params: { acc: this.$store.state.acc.current_acc.id },
      })
        .then((response) => {
          this.$vs.loading.close();
          const data = response.data.data;
          const wppTemplate = data.find(
            (e) => e.ID === this.currentWppTemplate.templateID
          );
          if (wppTemplate) {
            this.currentWppTemplate.templateStatus = wppTemplate.status;
            this.currentWppTemplate.rejectedReason = wppTemplate.rejectedReason;
          }
          this.allWppTemplates = data;

          Object.keys(this.allWppTemplates).forEach((index) => {
            this.allWppTemplates[index]["isActive"] = true;
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    submitWhatsappApiTemplate() {
      this.submitingWhatsappApiTemplate = true;
      this.$vs.loading();
      let message = this.message;
      let parmI = 0;

      const messageParts = message.match(/[^{}]+(?=})/gi);
      if (messageParts)
        messageParts.forEach((el) => {
          parmI += 1;
          message = message.replace(`{${el}}`, `{{${parmI}}}`);
        });

      let fd = new FormData();
      if (this.headerMedia) {
        fd.append("content", this.headerMedia);
        fd.append("headerMediaName", this.headerMediaFileName);
      }
      fd.append("category", this.currentWppTemplate.templateCategory);
      fd.append("name", this.currentWppTemplate.templateName);
      fd.append("language", "pt_BR");
      fd.append("messageText", message);
      fd.append("templateWithImageHeader", this.templateWithImageHeader);
      fd.append("quickMessageId", this.currentQuickMessageId);

      this.$http({
        method: "post",
        url: `/p/chat/inbox/provider/whatsapp-api/template/${this.currentChat.value}`,
        params: { acc: this.$store.state.acc.current_acc.id },
        data: fd,
      })
        .then((response) => {
          this.submitingWhatsappApiTemplate = false;
          this.$vs.loading.close();
          this.currentWppTemplate = response.data.data;
          this.getQuickMessages();
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Template criado com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.submitingWhatsappApiTemplate = false;
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    async deleteWppTemplate(name) {
      this.$vs.loading();
      await this.$http({
        method: "delete",
        url: `/p/chat/inbox/provider/whatsapp-api/template/${this.currentChat.value}`,
        params: { acc: this.$store.state.acc.current_acc.id },
        data: {
          quickMessageId: this.currentQuickMessageId,
          name: name,
        },
      })
        .then(() => {
          this.$vs.loading.close();
          this.getWhatsappApiTemplates();
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Template deletado com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    getFormatedTitle(name, status) {
      return `${name} - ${status}`;
    },
    // end wpp api
    async createQuickMessage() {
      const fd = new FormData();

      if (this.quickMessageMedia) {
        fd.append("content", this.quickMessageMedia);
      }

      fd.append("title", this.title);
      fd.append("message", this.message);
      if (this.intentions.length > 0) {
        this.intentions.forEach((item) => {
          if (item.value) fd.append("intentions[]", item.value);
        });
      }
      if (this.entities.length > 0) {
        this.entities.forEach((item) => {
          if (item.value) fd.append("entities[]", item.value);
        });
      }
      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append("createdBy", this.$store.state.user._id);

      try {
        this.$vs.loading();

        await axios.post("/p/chat/inbox/quickMessages", fd);
        this.$vs.notify({
          time: 2500,
          title: this.$t("Ok"),
          text: this.$t("QuickMessageCreated"),
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "success",
          position: "top-right",
        });

        this.getQuickMessages();
        this.popupActive = false;
        this.title = "";
        this.message = "";
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: e,
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.intentionsFilter =
          {
            label: "All",
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(normalizeString(val));
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    async edit() {
      const fd = new FormData();

      if (this.quickMessageMedia) {
        fd.append("content", this.quickMessageMedia);
      }

      fd.append("title", this.title);
      fd.append("message", this.message);
      if (this.intentions.length > 0) {
        this.intentions.forEach((item) => {
          if (item.value) fd.append("intentions[]", item.value);
        });
      }
      if (this.entities.length > 0) {
        this.entities.forEach((item) => {
          if (item.value) fd.append("entities[]", item.value);
        });
      }

      try {
        this.$vs.loading();
        await axios.put(`/p/chat/inbox/quickMessages/${this.editid}`, fd, {
          params: { acc: this.$store.state.acc.current_acc.id },
        });

        this.getQuickMessages();

        this.$vs.loading.close();

        this.$vs.notify({
          color: "success",
          title: this.$t("Confirm"),
          text: this.$t("EditedSuccessfully"),
          position: "top-right",
          time: 4000,
        });
      } catch (e) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("error"),
          text: e,
          position: "top-right",
          time: 4000,
        });
      }

      this.$vs.loading.close();
    },
    async getQuickMessages() {
      this.$vs.loading();
      const response = await this.$http.get("/p/chat/inbox/quickMessages", {
        params: { acc: this.$store.state.acc.current_acc.id },
      });

      if (response.status === 200) {
        this.$vs.loading.close();
        await this.$store.dispatch(
          "chat/setInboxQuickMessages",
          response.data.data
        );

        this.inboxQuickMessagesFiltered = response.data.data;
      } else {
        this.$vs.loading.close();
      }
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            await this.$store.dispatch("chat/setChatsItem", {
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
            });
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    replaceEntitiesIntentions(param) {
      if (!param.value || (param.value && param.value.length === 0)) {
        return this.$t("All");
      } else {
        let localArr = JSON.parse(JSON.stringify(param.value));
        if (Array.isArray(param.value)) {
          let index = param.value.indexOf("__all__");
          if (index > -1) localArr[index] = this.$t("All");
          index = param.value.indexOf("__other__");
          if (index > -1) localArr[index] = this.$t("Others");
        }
        return localArr;
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.context = {
      componentParent: this,
    };

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  async created() {
    await this.getQuickMessages();

    if (this.$store.state.chat.chats.length === 0) await this.getChats();

    this.$http.get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      }).then((res) => {
        if (res.data.data.inbox.ia) this.ia = res.data.data.inbox.ia;
        const keyEntities = [];
        res.data.data.inbox.keyEntities.forEach((value) => {
          keyEntities.push({ label: value, value });
        });
        this.inboxEntities = keyEntities;
        this.inboxEntities.unshift({
          label: this.$t("All"),
          value: "__all__",
        });
        this.createQuickMessagesOptions.entities = this.inboxEntities

        const intentions = [];
        res.data.data.inbox.intentions.forEach((value) => {
          intentions.push({ label: value, value });
        });
        this.intentionsOptions = [...new Set(intentions)];
        this.intentionsOptions.unshift({
          label: this.$t("All"),
          value: "__all__",
        });
        this.intentionsOptions.push({
          label: this.$t("Others"),
          value: "__other__",
        });
      this.createQuickMessagesOptions.intentions = this.intentionsOptions
    });
  },
};
</script>

<style lang="scss">
.duo-custom-radio {
  width: 100%;
  padding: 1.25rem;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  cursor: pointer;

  &:has(input:checked) {
    border-color: rgb(115, 103, 240);

    &::v-deep .vs-radio--circle {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 44%;
        height: 44%;
        background-color: #fff;
        border-radius: 100vw;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}
</style>
