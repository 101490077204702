<template>
  <span class="uppercase rounded-lg p-2" :class="statusStyle">{{
    params.value
  }}</span>
</template>

<script>
export default {
  name: "CellRendererStatus360Dialog",
  computed: {
    statusStyle() {
      let styles = ["text-white"];
      switch (this.params.value) {
        case "approved":
          styles.push("bg-success");
          break;
        case "rejected":
          styles.push("bg-danger");
          break;
        default:
          styles.push("bg-dark");
      }

      return styles;
    },
  },
};
</script>
