<template>
  <div
    class="con-vs-alert"
    :class="'con-vs-alert-'+color"
  >
    <div class="con-x vs-alert--close" @click="$emit('action')">
      <i class="vs-icon notranslate icon-scale material-icons null">close</i>
    </div>
    <h4 class="titlex vs-alert--title" v-html="title"></h4>
    <div class="vs-alert" v-html="description"></div>
  </div>
</template>
<script>
export default {
  name: "CustomAlert",
  props: {
    color: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: false
    }
  }
}
</script>
