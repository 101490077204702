<template>
  <div>
    <div v-if="!isFlowType" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
      <feather-icon
        icon="TrashIcon"
        svgClasses="h-5 w-5 hover:text-danger cursor-pointer ml-4"
        @click="confirmDelete"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFlowType: false,
      data: null,
    };
  },
  name: "CellRendererActions",
  mounted() {
    this.data = this.params.data;
    this.isFlowType = this.params.data.isFlowType;
  },
  methods: {
    confirmDelete() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmDeleteIntentionTitle"),
        text: this.$t("ConfirmDeleteIntention"),
        accept: this.delete,
        acceptText: this.$t("Inactivate"),
        cancelText: this.$t("Cancel"),
      });
    },
    delete() {
      this.$http
        .put(
          `/p/chat/inbox/config`,
          {
            rmIntention: this.data.isIntention,
            rmEntity: this.data.isEntity,
            intention: this.data.name,
            entity: this.data.name,
          },
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        )
        .then(async () => {
          this.$vs.loading.close();
          this.params.context.componentParent.removeElement(this.data.name);
          this.params.context.componentParent.$emit("getInbox");
          this.$vs.notify({
            color: "success",
            title: this.$t("Confirm"),
            text: this.$t("DeletedSuccessfuly"),
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
