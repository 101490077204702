var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-chip',{attrs:{"transparent":"","color":_vm.params.data.status === 'Error'
        ? 'danger'
        : _vm.params.data.status === 'Treinando'
        ? 'primary'
        : _vm.params.data.status === 'Treinado'
        ? 'success'
        : 'dark'}},[_vm._v(" "+_vm._s(_vm.params.data.status === "Error" ? "ERRO" : _vm.params.data.status === "Treinando" ? "TREINANDO" : _vm.params.data.status === "Treinado" ? "TREINADO" : "NÃO TREINADO")+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }