<template>
  <div class="flex justify-center">
    <vs-button
      v-if="params.data.type !== 'Url'"
      type="flat"
      color="dark"
      icon-pack="feather"
      icon="icon-download"
      class="mt-1"
      :href="params.data.url"
    />
    <vs-button
      type="flat"
      color="danger"
      icon-pack="feather"
      icon="icon-trash"
      class="mt-1"
      @click="popupDeleteFile = true"
    />
    <vs-popup title="Deletar conteúdo" :active.sync="popupDeleteFile">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover o conteúdo - {{ params.data.name }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteFile(params.data._id)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
export default {
  name: "BaseActions",
  data() {
    return {
      popupDeleteFile: false,
    };
  },
  methods: {
    async deleteFile(id) {
      try {
        const response = await this.$http.delete(
          "/p/chat/ai/fine-tuning/files/delete/" +
            id +
            "?acc=" +
            this.params.data.acc +
            "&chat=" +
            this.params.data.chat
        );

        this.$store.dispatch("acc/removeAiFileById", id);

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: response.data.message,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });

        this.params.api.applyTransaction({
          remove: this.params.api.getSelectedRows(),
        });

        this.popupDeleteFile = false;
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
  },
};
</script>
