<!-- =========================================================================================
  File Name: InboxWhatsappTemplates.vue
  Description: Inbox whatsapp api templates
========================================================================================== -->

<template>
  <div id="page-user-list">
    <div class="flex flex-wrap items-center justify-between">
      <div class="w-auto flex">
        <v-select
          :options="chatOptions"
          :clearable="false"
          v-model="chatFilter"
          class="flex-none mr-4 w-1/2"
          label="Chatbot"
        />
        <vs-input
          class="flex-none sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
          icon="icon-search"
          icon-pack="feather"
          v-model="searchQuery"
          @input="updateSearchQuery"
          :placeholder="$t('Search')"
        />
      </div>
      <vs-button
        icon-pack="feather"
        icon="icon-file-text"
        size="medium"
        class="mb-4 md:mb-0"
        color="success"
        @click="popupActive = true"
        >{{ $t("AddQuickMessage") }}</vs-button
      >
      <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->
    </div>

    <!-- AgGrid Table -->
    <ag-grid-vue
      @gridReady="onGridReady"
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      class="ag-theme-material my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="messagesData"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
      id="quick-messages-table"
    ></ag-grid-vue>

    <vs-popup :title="$t('QuickMessages')" :active.sync="popupActive">
      <div class="flex flex-wrap">
        <div class="w-full">
          <p>
            <vs-input
              name="email"
              v-model="title"
              type="email"
              class="w-full my-3"
              :label="$t('Title')"
            />

            <small>{{ $t("Intention") }}</small>
            <v-select
              v-model="intentions"
              :clearable="false"
              :options="intentionsOptions"
              multiple
              name="intentions"
              class="w-full intentions"
            />

            <small>{{ $t("KeyEntities") }}</small>
            <v-select
              id="entities"
              v-model="entities"
              :clearable="false"
              :options="inboxEntities"
              multiple
              name="entities"
              class="w-full"
            />

            <vs-textarea
              name="email"
              v-model="message"
              class="w-full my-3"
              placeholder="Escreva uma mensagem..."
            >
            </vs-textarea>
            <small>
              * Digite {NOME_ATENDENTE} para adicionar o nome do atendente,
              {NOME_CONTATO} para o nome do contato e {ID_CONVERSA} para adicionar o id da conversa.
              <a
                href="https://ajuda.duotalk.io/pt/articles/52-enviar-mensagens-com-variaveis"
                target="_blank"
                class="underline"
                >Saiba mais</a
              >
              sobre como utilizar outras variáveis customizáveis nas mensagens
              templates
            </small>
          </p>

          <vs-button
            icon-pack="feather"
            icon="icon-file-plus"
            size="medium"
            class="mt-4 mb-4 md:mb-0"
            @click="createQuickMessage()"
            >{{ $t("Save") }}</vs-button
          >
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="holamundo"
      title="Template WhatsApp"
      :active.sync="popupWhatsappActive"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <p>
            Submeter template de mensagem para aprovação do Facebook / WhatsApp
          </p>

          <div v-if="currentChat">
            <div v-if="showAllWppTemplates">
              <p class="mt-3">
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="showAllWppTemplates = false"
                  >Voltar</span
                >
              </p>
              <p class="mt-3">Lista de todos os templates aprovados.</p>
              <vs-list>
                <vs-list-item
                  :title="getFormatedTitle(item.name, item.status)"
                  :subtitle="item.components[0].parameters[0].text"
                  v-for="(item, i) in allWppTemplates"
                  :key="i"
                >
                  <div>
                    <vs-button
                      color="danger"
                      size="small"
                      class="ml-3"
                      @click="deleteWppTemplate(item.name)"
                      >{{ $t("Delete") }}</vs-button
                    >
                  </div>
                </vs-list-item>
              </vs-list>
            </div>
            <div v-else>
              <p class="mt-3">
                <span
                  style="cursor: pointer"
                  class="text-primary"
                  @click="showAllWppTemplates = true"
                  >Verificar templates divergentes</span
                >
              </p>
              <p class="mt-3">
                Status atual:
                <span v-if="!currentWppTemplate.templateStatus"
                  ><b>Não Submetido</b></span
                >
                <span v-else
                  ><b>{{ currentWppTemplate.templateStatus }}</b></span
                >
              </p>
              <p v-if="currentWppTemplate.rejectedReason" class="mt-3">
                Motivo da negativa:
                <b>{{ currentWppTemplate.rejectedReason }}</b>
              </p>
              <p class="mt-3">
                {{ $t("Category") }}
              </p>
              <div class="flex flex-wrap mt-3">
                <div
                  v-for="(item, i) in wppApiTemplateCategoryOptions"
                  :key="i"
                  class="p-2"
                >
                  <vs-radio
                    v-model="currentWppTemplate.templateCategory"
                    :vs-value="item.id"
                  >
                    <vx-tooltip :text="item.label">
                      <small class="text-xs"
                        ><b>{{ item.id }}</b></small
                      >
                    </vx-tooltip>
                  </vs-radio>
                </div>
                <span class="text-warning" v-if="currentWppTemplate.isModified"
                  >O texto do template foi alterado, submeta o template
                  novamente para refletir as alterações no WhatsApp</span
                >
              </div>

              <vs-button
                size="medium"
                class="mt-4 mb-4 md:mb-0"
                @click="submitWhatsappApiTemplate()"
                :disabled="
                  submitingWhatsappApiTemplate ||
                  !currentWppTemplate.templateCategory ||
                  (currentWppTemplate.templateStatus === 'APPROVED' &&
                    currentWppTemplate.isModified === false)
                "
                >{{ $t("SubmitTemplate") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="holamundo"
      :title="$t('Edit')"
      :active.sync="popupEditActive"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="w-full">
            <p>{{ title }}</p>
            <small>{{ $t("Intention") }}</small>
            <v-select
              id="intentions"
              v-model="intentions"
              :clearable="false"
              :options="intentionsOptions"
              multiple
              name="intentions"
              class="w-full"
            />

            <small>{{ $t("KeyEntities") }}</small>
            <v-select
              id="entities"
              v-model="entities"
              :clearable="false"
              :options="inboxEntities"
              multiple
              name="entities"
              class="w-full"
            />
            <vs-textarea
              name="message"
              v-model="message"
              class="w-full my-3"
              rows="10"
              disabled
              placeholder="Escreva uma mensagem..."
            >
            </vs-textarea>
            <small>Não é possível editar a mensagem.</small>
          </div>

          <vs-button
            icon-pack="feather"
            icon="icon-file-plus"
            size="medium"
            class="mt-4 mb-4 md:mb-0"
            @click="edit()"
            >{{ $t("Save") }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

import CellRendererStatus from "./cell-render-whatsapp-templates/CellRendererStatus.vue";
import CellRendererActions from "./cell-render-whatsapp-templates/CellRendererActions.vue";

import vSelect from "vue-select";

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    CellRendererStatus,
    CellRendererActions,
    "v-select": vSelect,
  },
  data() {
    return {
      // start wpp data
      submitingWhatsappApiTemplate: false,
      currentQuickMessageId: null,
      showAllWppTemplates: false,
      popupWhatsappActive: false,
      chatFilter: null,
      currentChat: null,
      allWppTemplates: [],
      wppTemplateCategory: null,
      currentWppTemplate: null,
      quickMessage: null,
      wppTemplate: {
        category: null,
        name: "",
        language: "pt_BR",
        components: [
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: "",
              },
            ],
          },
        ],
      },
      // end wpp data
      popupEditActive: false,
      inboxID: null,
      editid: null,
      inboxIntentions: [],
      acl: "",
      aclOptions: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: this.$t("Manager"),
          value: "manager",
        },
        {
          label: this.$t("Operator"),
          value: "operator",
        },
      ],
      title: "",
      intentionsOptions: [],
      intentions: [],
      inboxEntities: [],
      entities: [],
      message: "",
      popupActive: false,
      searchQuery: "",
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$t("Title"),
          field: "title",
          filter: true,
        },
        {
          headerName: this.$t("Message"),
          field: "message",
          filter: true,
        },
        {
          headerName: this.$t("Intentions"),
          field: "intentions",
          filter: true,
        },
        {
          headerName: this.$t("KeyEntities"),
          field: "entities",
          filter: true,
        },
        {
          headerName: this.$t("Status"),
          field: "status",
          filter: true,
        },
        {
          headerName: this.$t("CardActions"),
          field: "_id",
          cellRenderer: "CellRendererActions",
        },
      ],
      components: {
        //CellRendererLink,
        CellRendererStatus,
        //CellRendererVerified
        CellRendererActions,
      },
      inboxQuickMessagesFiltered: [],
    };
  },
  watch: {
    popupEditActive(val) {
      if (!val) {
        this.title = "";
        this.message = "";
        this.intentions = [];
        this.entities = [];
      }
    },
    popupActive(val) {
      if (!val) {
        this.title = "";
        this.message = "";
        this.intentions = [];
        this.entities = [];
      } else {
        window.analytics.track(
          "Novo Template",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
      }
    },
    popupWhatsappActive(val) {
      if (!val) {
        this.currentChat = null;
        this.chatFilter = null;
        this.showAllWppTemplates = false;
      }
    },
    chatFilter(val) {
      this.currentChat = val.value;
      if (val.value) {
        this.getWhatsappApiTemplates();
        this.currentWppTemplate = {
          chat: this.currentChat,
          templateName: "",
          templateID: "",
          templateStatus: "Não Submetido",
          templateCategory: "",
          rejectedReason: null,
          isModified: false,
        };
        this.currentWppTemplate.templateName =
          `tp_${this.title}_${this.chatFilter.label}`
            .replace(" ", "_")
            .replace(/[^a-zA-Z_]/g, "")
            .toLowerCase();
        if (this.quickMessage.whatsappApiTemplates) {
          const wppTemplate = this.quickMessage.whatsappApiTemplates.find(
            (e) => e.chat === this.currentChat
          );
          if (wppTemplate) this.currentWppTemplate = wppTemplate;
        }
      }
    },
  },
  computed: {
    chatOptions() {
      return [{ label: this.$t("ChooseOneChatbot"), value: "" }].concat(
        this.$store.state.chat.chats
      );
    },
    wppApiTemplateCategoryOptions: function () {
      return [
        { id: "UTILITY", label: this.$t("WppApiTemplateCategory12") },
        { id: "MARKETING", label: this.$t("WppApiTemplateCategory13") },
        { id: "AUTHENTICATION", label: this.$t("WppApiTemplateCategory14") },
      ];
    },
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    messagesData() {
      return this.$store.state.chat.inboxQuickMessages;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    openEdit(id) {
      this.editid = id;
      const quickMessage = this.$store.state.chat.inboxQuickMessages.find(
        (i) => i._id === id
      );
      this.title = quickMessage.title;
      this.message = quickMessage.message;
      this.entities = quickMessage.entities || [];
      this.intentions = quickMessage.intentions || [];
      this.popupEditActive = true;
    },
    // start wpp api
    deleteAvailable(id) {
      const wppTemplate = this.inboxQuickMessagesFiltered.find((i) => {
        if (
          i.whatsappApiTemplates &&
          i.whatsappApiTemplates.length > 0 &&
          i.whatsappApiTemplates.find((el) => el.templateID === id)
        )
          return true;
      });
      if (wppTemplate) return false;
      return true;
    },
    openWppPopup(id) {
      this.currentQuickMessageId = id;
      this.popupWhatsappActive = true;
      const quickMessage = this.inboxQuickMessagesFiltered.find(
        (i) => i._id === this.currentQuickMessageId
      );
      this.quickMessage = quickMessage;
      this.title = quickMessage.title;
      this.message = quickMessage.message;
      this.popupWhatsappActive = true;
    },
    getWhatsappApiTemplates() {
      this.$vs.loading();
      axios({
        method: "get",
        url: `/p/chat/inbox/provider/whatsapp-api/templates/${this.currentChat}`,
        params: { acc: this.$store.state.acc.current_acc.id },
      })
        .then((response) => {
          this.$vs.loading.close();
          const data = response.data.data;
          const wppTemplate = data.find(
            (e) => e.ID === this.currentWppTemplate.templateID
          );
          if (wppTemplate) {
            this.currentWppTemplate.templateStatus = wppTemplate.status;
            this.currentWppTemplate.rejectedReason = wppTemplate.rejectedReason;
          }
          this.allWppTemplates = data;
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    submitWhatsappApiTemplate() {
      this.submitingWhatsappApiTemplate = true;
      this.$vs.loading();
      let paramsNum = 0;
      let message = "";
      if (this.message.search("{NOME_CONTATO}") > -1) paramsNum += 1;
      if (this.message.search("{NOME_ATENDENTE}") > -1) paramsNum += 1;

      message = this.message
        .replace("{NOME_CONTATO}", "{{1}}")
        .replace("{NOME_ATENDENTE}", `{{${paramsNum === 2 ? 2 : 1}}}`);
      axios({
        method: "post",
        url: `/p/chat/inbox/provider/whatsapp-api/template/${this.currentChat}`,
        params: { acc: this.$store.state.acc.current_acc.id },
        data: {
          category: this.currentWppTemplate.templateCategory,
          name: this.currentWppTemplate.templateName,
          language: "pt_BR",
          messageText: message,
          quickMessageId: this.currentQuickMessageId,
        },
      })
        .then((response) => {
          this.submitingWhatsappApiTemplate = false;
          this.$vs.loading.close();
          const data = response.data.data;
          this.currentWppTemplate = data;
          this.getQuickMessages();
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Template criado com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.submitingWhatsappApiTemplate = false;
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    deleteWppTemplate(name) {
      this.$vs.loading();
      axios({
        method: "delete",
        url: `/p/chat/inbox/provider/whatsapp-api/template/${this.currentChat}`,
        params: { acc: this.$store.state.acc.current_acc.id },
        data: {
          quickMessageId: this.currentQuickMessageId,
          name: name,
        },
      })
        .then(() => {
          this.$vs.loading.close();
          this.getWhatsappApiTemplates();
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Template deletado com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    getFormatedTitle(name, status) {
      return `${name} - ${status}`;
    },
    // end wpp api
    createQuickMessage() {
      this.$http
        .post("/p/chat/inbox/quickMessages", {
          title: this.title,
          message: this.message,
          intentions: this.intentions,
          entities: this.entities,
          acc: this.$store.state.acc.current_acc.id,
        })
        .then(async () => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Ok"),
            text: this.$t("QuickMessageCreated"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
          });

          this.getQuickMessages();
          this.popupActive = false;
          this.title = "";
          this.message = "";
        })
        .catch(() => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Ok"),
            text: this.$t("Error"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();

      // Reset Filter Options
      this.roleFilter =
        this.statusFilter =
        this.isVerifiedFilter =
        this.intentionsFilter =
          {
            label: "All",
            value: "all",
          };

      this.$refs.filterCard.removeRefreshAnimation();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onGridReady(params) {
      params.api.sizeColumnsToFit();
    },
    edit() {
      this.$http
        .put(
          `/p/chat/inbox/quickMessages/${this.editid}`,
          {
            title: this.title,
            message: this.message,
            intentions: this.intentions,
            entities: this.entities,
          },
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        )
        .then(async () => {
          this.$vs.loading.close();
          this.getQuickMessages();
          this.$vs.notify({
            color: "success",
            title: this.$t("Confirm"),
            text: this.$t("EditedSuccessfully"),
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    getQuickMessages() {
      this.$vs.loading();
      this.$http
        .get("/p/chat/inbox/quickMessages", {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          this.$vs.loading.close();
          await this.$store.dispatch(
            "chat/setInboxQuickMessages",
            response.data.data
          );

          this.inboxQuickMessagesFiltered = response.data.data;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (response) => {
          for (var i = 0; i < response.data.data.length; i++) {
            this.$store.dispatch("chat/setChatsItem", {
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
            });
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridOptions.context = {
      componentParent: this,
    };

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  created() {
    this.getQuickMessages();
    if (this.$store.state.chat.chats.length === 0) this.getChats();

    this.$http
      .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      })
      .then((res) => {
        const entities = res.data.data.inbox.keyEntities;
        this.inboxEntities = entities;

        const intentions = res.data.data.inbox.intentions;
        this.intentionsOptions = [...new Set(intentions)];
      });
  },
};
</script>

<style lang="scss">
#quick-messages-table {
  max-height: 500px;
}
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

#intentions {
  margin-bottom: 10px;
}
</style>
