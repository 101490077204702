<!-- =========================================================================================
  File Name: InboxSettings.vue
  Description: Inbox Settings
========================================================================================== -->

<template>
  <vs-row>
    <vs-col vs-w="12">
      <vs-card class="p-4" v-if="inboxData">
        <div class="w-full md:flex lg:flex">
          <vs-button
            :type="currentTab === 0 ? 'filled' : 'flat'"
            @click="activeTab = 0"
            class="px-2"
          >
            Geral
          </vs-button>
          <vs-button
            class="ml-3 px-2"
            :type="currentTab === 1 ? 'filled' : 'flat'"
            @click="activeTab = 1"
          >
            Intenções / Entidades Chave
          </vs-button>
          <vs-button
            :type="currentTab === 2 ? 'filled' : 'flat'"
            class="ml-3 px-2"
            @click="activeTab = 2"
          >
            Mensagens automáticas
          </vs-button>
          <vs-button
            :type="currentTab === 3 ? 'filled' : 'flat'"
            class="ml-3 px-2"
            @click="activeTab = 3"
          >
            Mensagens rápidas
          </vs-button>
          <vs-button
            :type="currentTab === 4 ? 'filled' : 'flat'"
            class="ml-3 hidden px-2"
            @click="activeTab = 4"
            v-if="inboxHasWppAPI"
          >
            Templates WhatsApp
          </vs-button>
          <vs-button
            :type="currentTab === 5 ? 'filled' : 'flat'"
            class="ml-3 px-2"
            @click="activeTab = 5"
            v-if="inboxHasWppAPI"
          >
            Templates 360 Dialog
          </vs-button>
        </div>
        <vs-row>
          <vs-col vs-w="12" class="mb-5">
            <vs-divider />
          </vs-col>
        </vs-row>
        <InboxGeneralSettings v-if="currentTab === 0" />
        <InboxIntentsEntitiesList
          :inboxData="inboxData"
          v-else-if="currentTab === 1"
          @getInbox="getInbox"
        />
        <AutoMessagesList v-else-if="currentTab === 2" />
        <QuickMessagesList v-else-if="currentTab === 3" />
        <InboxWhatsappTemplates v-else-if="currentTab === 4" />
        <Inbox360DialogTemplates v-else-if="currentTab === 5" />
      </vs-card>
    </vs-col>
  </vs-row>
</template>

<script>
import AutoMessagesList from "../auto-messages-list/AutoMessagesList.vue";
import QuickMessagesList from "../quick-messages-list/QuickMessagesList.vue";
import Inbox360DialogTemplates from "./Inbox360DialogTemplates/Inbox360DialogTemplates.vue";
import InboxGeneralSettings from "./InboxGeneralSettings/InboxGeneralSettings.vue";
import InboxIntentsEntitiesList from "./InboxIntentsEntitiesList.vue";
import InboxWhatsappTemplates from "./InboxWhatsappTemplates.vue";

export default {
  components: {
    InboxIntentsEntitiesList,
    QuickMessagesList,
    AutoMessagesList,
    InboxWhatsappTemplates,
    Inbox360DialogTemplates,
    InboxGeneralSettings,
  },
  props: {
    setActiveTab: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      colorx: "success",
      activeTab: null,
      inboxData: null,
    };
  },
  methods: {
    getInbox() {
      this.inboxIntentionsEntities = [];
      this.$vs.loading();
      this.$http
        .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          this.inboxData = response.data.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  computed: {
    inboxHasWppAPI() {
      return this.inboxData.inboxChatsConfig.map((el) => el.wppApiStatus === 1);
    },
    currentTab() {
      return this.activeTab !== null ? this.activeTab : this.setActiveTab;
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
  },
  mounted() {
    this.getInbox();
  },
  provide: function () {
    return {
      getInbox: this.getInbox,
    };
  },
};
</script>
