<!-- =========================================================================================
  File Name: InboxIntentsEntitiesList.vue
  Description: Inbox intentions and entities
========================================================================================== -->

<template>
  <div>
    <vs-popup class="holamundo" :title="$t('Add')" :active.sync="popupActive">
      <div class="flex flex-wrap">
        <div class="w-full">
          <div class="mt-3 ml-1">
            <vs-input
              name="intention"
              v-model="intention"
              type="text"
              class="w-full input--lowercase my-3"
              :label="$t('Intention')"
            />
          </div>
          <vs-divider />
          <vs-button @click="addIntention" size="medium" class="mb-4 md:mb-0">{{
            $t("Save")
          }}</vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-row>
      <vs-col vs-type="flex" vs-align="center" vs-justify="space-between">
        <vs-input
          icon="icon-search"
          icon-pack="feather"
          v-model="searchQuery"
          @input="updateSearchQuery"
          :placeholder="$t('Search')"
        />
        <vs-button
          icon-pack="feather"
          icon="icon-plus"
          size="medium"
          color="success"
          @click="popupActive = true"
          >{{ $t("Add") }} {{ $t("Intention") }}</vs-button
        >
      </vs-col>
    </vs-row>
    <ag-grid-vue
      @getInbox="this.$emit('getInbox')"
      @gridReady="onGridReady"
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      class="ag-theme-material ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="getIntentsAndEntities"
      rowSelection="multiple"
      colResizeDefault="shift"
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :enableRtl="$vs.rtl"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import vSelect from "vue-select";
import normalizeString from "@/helpers/normalizeString"

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    AgGridVue,
    // Cell Renderer
    CellRendererActions,
    "v-select": vSelect,
  },
  props: {
    inboxData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      intention: "",
      searchQuery: null,
      popupActive: false,
      inboxID: null,
      inboxIntentionsEntities: [],
      acl: "",
      aclOptions: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: this.$t("Manager"),
          value: "manager",
        },
        {
          label: this.$t("Operator"),
          value: "operator",
        },
      ],
      gridApi: null,
      gridOptions: {
        context: {
          componentParent: this,
        },
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$t("Name"),
          field: "name",
          filter: true,
          getQuickFilterText: params => {
            return normalizeString(params.value)
          }
        },
        {
          headerName: this.$t("Type"),
          field: "type",
          filter: true,
        },
        {
          headerName: this.$t("CardActions"),
          field: "name",
          cellRenderer: "CellRendererActions",
          width: 50,
        },
      ],
      components: {
        CellRendererActions,
      },
    };
  },
  computed: {
    getIntentsAndEntities() {
      const data = this.inboxData.inbox;
      const intentions = [];
      data.intentions.forEach((intent) => {
        let flowType = this.$t("No");
        let isFlowType = false;
        if (data.flowIntentions && data.flowIntentions.includes(intent)) {
          isFlowType = true;
          flowType = this.$t("Yes");
        }
        intentions.push({
          name: intent,
          isIntention: true,
          type: this.$t("Intention"),
          flowType,
          isFlowType,
        });
      });

      const entities = [];
      data.keyEntities.forEach((entity) => {
        let flowEntity = this.$t("No");
        let isFlowType = false;
        if (data.flowKeyEntities && data.flowKeyEntities.includes(entity)) {
          isFlowType = true;
          flowEntity = this.$t("Yes");
        }
        entities.push({
          name: entity,
          isEntity: true,
          type: this.$t("EntityKey"),
          flowEntity,
          isFlowType,
        });
      });

      return intentions.concat(entities);
    },
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(normalizeString(val));
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit()
    },
    removeElement(name) {
      const index = this.inboxIntentionsEntities.findIndex(
        (el) => el.name === name
      );
      if (index > -1) this.inboxIntentionsEntities.splice(index, 1);
    },
    addIntention() {
      this.$vs.loading();
      const data = {};
      if (this.intention)
        (data.addIntention = true), (data.intention = this.intention);

      this.$http
        .put(`/p/chat/inbox/config`, data, {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async () => {
          this.$vs.loading.close();
          this.intention = "";
          this.popupActive = false;
          this.$emit("getInbox");
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: this.$t("Success"),
            text: this.$t("AddedSuccessfuly"),
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we gave fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
