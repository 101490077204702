<template>
  <div>
    <div :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
      <feather-icon
        v-if="hasInbox"
        icon="EditIcon"
        svgClasses="h-5 w-5 hover:text-success cursor-pointer"
        @click="openEdit"
      />
      <span class="feather-icon select-none relative">
        <whatsapp-icon
          v-if="hasInbox"
          color="#626262"
          hoverColor="#28c76f"
          class="feather h-5 w-5 cursor-pointer mr-3 ml-3"
          @click.native="openWppPopup"
        />
      </span>

      <feather-icon
        icon="Trash2Icon"
        svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
        @click="confirmDeleteRecord"
      />
    </div>

    <vs-popup
      class="holamundo"
      :title="$t('View')"
      :active.sync="popupViewActive"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <p>{{ title }}</p>
          <vs-textarea
            name="message"
            v-model="message"
            class="w-full my-3"
            rows="10"
            disabled
            placeholder="Escreva uma mensagem..."
          >
          </vs-textarea>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAllWppTemplates: false,
      popupActive: false,
      popupViewActive: false,
      popupWhatsappActive: false,
      title: "",
      message: "",
      chatFilter: null,
      currentChat: null,
      allWppTemplates: [],
      wppTemplateCategory: null,
      currentWppTemplate: null,
      quickMessage: null,
      wppTemplate: {
        category: null,
        name: "",
        language: "pt_BR",
        components: [
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: "",
              },
            ],
          },
        ],
      },
    };
  },
  name: "CellRendererActions",
  computed: {
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
  },
  methods: {
    openWppPopup() {
      this.params.context.componentParent.openWppPopup(this.params.value);
    },
    openEdit() {
      this.params.context.componentParent.openEdit(this.params.value);
    },
    getQuickMessages() {
      this.$vs.loading();
      this.$http
        .get("/p/chat/inbox/quickMessages", {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          this.$vs.loading.close();
          await this.$store.dispatch(
            "chat/setInboxQuickMessages",
            response.data.data
          );

          this.inboxQuickMessagesFiltered = response.data.data;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmRemoveUserAccess"),
        text: this.$t("ConfirmRemoveQuickMessage"),
        accept: this.deleteRecord,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    deleteRecord() {
      /* UnComment below lines for enabling true flow if deleting user */
      this.$http
        .delete(`/p/chat/inbox/quickMessages/${this.params.value}`, {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async () => {
          this.$vs.loading.close();
          this.showDeleteSuccess();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    showDeleteSuccess() {
      this.getQuickMessages();
      this.$vs.notify({
        color: "success",
        title: this.$t("Confirm"),
        text: this.$t("User") + " " + this.$t("DeletedSuccessfuly"),
        position: "top-right",
        time: 4000,
      });
    },
    openView() {
      const quickMessage = this.$store.state.chat.inboxQuickMessages.find(
        (i) => i._id === this.params.value
      );
      this.title = quickMessage.title;
      this.message = quickMessage.message;
      this.popupViewActive = true;
    },
  },
  mounted() {},
};
</script>
<style>
.vs-tooltip {
  z-index: 999000;
}
</style>