<template>
  <span
    v-if="params.value.format"
    class="uppercase rounded-lg p-2"
    :class="statusStyle"
  >
    {{ params.value.format }}
  </span>
</template>

<script>
export default {
  name: "CellRendererMediaStatus360Dialog",
  computed: {
    statusStyle() {
      let styles = ["text-white"];

      if (this.params.value.withMedia) {
        styles.push("bg-success");
        return styles;
      }

      styles.push("bg-danger");

      return styles;
    },
  },
};
</script>
