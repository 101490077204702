<template>
  <div>
    <vs-row>
      <vs-col
        vs-w="12"
        vs-type="flex"
        vs-justify="space-between"
        vs-align="end"
      >
        <div class="flex items-end">
          <vs-select v-model="currentChat" label="Chatbot">
            <vs-select-item
              v-for="(item, index) in chatOptions"
              :key="index"
              :text="item.label"
              :value="item.value"
            />
          </vs-select>
          <vs-input
            type="search"
            class="ml-5"
            placeholder="Procurar"
            v-model="search"
            v-on:input="onFilterFiles()"
            icon-pack="feather"
            icon="icon-search"
          />
        </div>
        <div class="flex items-end">
          <vs-button
            ref="trainingButton"
            color="primary"
            icon-pack="feather"
            icon="icon-zap"
            icon-after
            class="mr-4 px-2 vs-con-loading__container"
            @click="trainingFineTuning()"
            :loading="true"
            :disabled="fineTuningIsRunning"
          >
            <p v-if="fineTuningIsRunning">Treinando</p>
            <p v-else>Treinar IA</p>
          </vs-button>
          <vs-button
            color="success"
            icon-pack="feather"
            icon="icon-upload"
            icon-after
            @click="clickPopupUpload()"
            class="px-2"
          >
            Adicionar conteúdo
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="12" class="mt-5">
        <ag-grid-vue
          class="ag-theme-material ag-grid-table w-full h-full knowledge-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          :gridOptions="gridOptions"
          rowSelection="single"
          colResizeDefault="shift"
          @grid-ready="onGridReady"
          domLayout="autoHeight"
          :pagination="true"
          :paginationPageSize="10"
          :suppressPaginationPanel="true"
          @pagination-changed="onPaginationChanged"
          :overlayNoRowsTemplate="$t('EmptyTable')"
          :enableRtl="$vs.rtl"
        />
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col v-if="totalPages" vs-w="12" vs-type="flex" vs-justify="end">
        <CustomPagination
          :totalPages="totalPages"
          :currentPage="current"
          @prev="gridOptions.paginationGoToPreviousPage()"
          @next="gridOptions.paginationGoToNextPage()"
          @goToPage="(page) => gridOptions.paginationGoToPage(page)"
        />
      </vs-col>
    </vs-row>
    <vs-popup title="Adicionar conteúdo" :active.sync="popupUpload">
      <vs-row>
        <vs-col>
          <input
            type="file"
            class="hidden"
            ref="fileUpload"
            accept=".pdf, .xls, .xlsx, .csv, .doc, .docx"
            multiple
            v-on:input="handleFilesBeforeUpload"
          />
          <vs-button
            icon-pack="feather"
            icon="icon-upload"
            icon-after
            :disabled="disableButtons"
            @click="$refs.fileUpload.click()"
            class="px-2"
          >
            Adicionar arquivos
          </vs-button>
        </vs-col>
        <vs-col class="mt-5">
          <p class="text-base">
            Formatos suportados: pdf, xls, xlsx, csv, doc, docx.
          </p>
        </vs-col>
        <vs-divider />
        <vs-col>
          <p>URL do conteúdo</p>
          <vs-row>
            <vs-col vs-w="10">
              <vs-input
                class="w-full my-2"
                placeholder="URL do site"
                v-model="crawlerUrl"
              />
            </vs-col>
            <vs-col vs-w="2" vs-type="flex" vs-align="center" vs-justify="end">
              <vs-button
                icon-pack="feather"
                icon="icon-plus"
                icon-after
                :disabled="disableButtons"
                @click="addCrawlerUrl"
                class="px-2 ml-1"
              >
                Add
              </vs-button>
            </vs-col>
          </vs-row>
          <p class="text-lg">
            Faremos o treinamento do conteúdo apenas da url fornecida
          </p>
          <p class="text-base">
            Conteúdos
            <span class="font-bold">{{ totalFiles }}/{{ filesLimit }}</span>
          </p>
        </vs-col>
        <vs-col class="mt-5">
          <custom-tag
            v-for="(item, index) in filesToUpload"
            :key="index"
            :label="item.name || item"
            color="grey"
            remove
            @remove="removeFile(index)"
            :class="{ 'mt-3': index > 0 }"
          />
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            icon-pack="feather"
            icon="icon-save"
            icon-after
            class="px-2"
            @click="handleUpload()"
          >
            {{ $t("Save") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import StatusLabel from "./StatusLabel.vue";
import BaseActions from "./BaseActions.vue";
import CustomTag from "@/components/CustomTag.vue";
import chat from "@/views/apps/inbox/Chat.vue";
import CustomPagination from "@/components/CustomPagination.vue";

export default {
  name: "KnowledgeBase",
  components: {
    CustomPagination,
    CustomTag,
    "ag-grid-vue": AgGridVue,
    /* eslint-disable vue/no-unused-components */
    StatusLabel,
    BaseActions,
  },
  data() {
    return {
      crawlerUrl: "",
      currentChat: null,
      search: null,
      columnDefs: [
        {
          headerName: "Conteúdo",
          field: "name",
          cellDataType: "text",
          filter: false,
          cellClass: "flex items-center",
        },
        {
          headerName: "Tipo",
          field: "type",
          filter: false,
          cellClass: "flex items-center",
        },
        {
          headerName: "Status",
          field: "status",
          filter: false,
          cellRenderer: "StatusLabel",
          cellClass: "flex items-center",
        },
        {
          headerName: "Ações",
          field: "actions",
          filter: false,
          cellRenderer: "BaseActions",
          resizable: false,
          sortable: false,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: false,
        suppressMenu: false,
      },
      gridOptions: {},
      popupUpload: false,
      filesToUpload: [],
      popupDelete: false,
      removeFileItem: null,
      current: 1,
      totalPages: null,
      gridReady: false,
      fineTunningStatus: null,
    };
  },
  computed: {
    filesLimit() {
      return 10;
    },
    disableButtons() {
      return this.totalFiles >= this.filesLimit;
    },
    rowData() {
      return this.$store.state.acc.aiFiles;
    },
    totalFiles() {
      return this.rowData.length + this.filesToUpload.length;
    },
    chat() {
      return chat;
    },
    chatOptions() {
      return this.$store.state.chat.chats;
    },
    fineTuningIsRunning() {
      return (
        this.fineTunningStatus &&
        (this.fineTunningStatus.status === "validating_files" ||
          this.fineTunningStatus.status === "running" ||
          this.fineTunningStatus.status === "queued")
      );
    },
  },
  async mounted() {
    if (
      !this.$store.state.chat.chats ||
      this.$store.state.chat.chats.length === 0
    ) {
      await this.getChats();
    } else {
      this.currentChat = this.chatOptions[0].value;
    }
  },
  methods: {
    addCrawlerUrl() {
      if (this.crawlerUrl.trim() === "") return;
      this.filesToUpload.unshift(this.crawlerUrl);
      this.crawlerUrl = "";
    },
    async getFineTunningStatus() {
      if (!this.currentChat) return;
      try {
        const response = await this.$http.get(
          "/p/chat/ai/fine-tuning?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.currentChat
        );

        this.fineTunningStatus = response.data.data;
      } catch (e) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: e.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }

      await this.listFiles();
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (response) => {
          this.currentChat = response.data.data[0]._id;

          for (let i = 0; i < response.data.data.length; i++) {
            await this.$store.dispatch("chat/setChatsItem", {
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
            });
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    onGridReady(params) {
      this.gridOptions = params.api;

      params.api.setRowData(this.rowData);

      this.gridReady = true;
    },
    onPaginationChanged() {
      if (this.gridReady) {
        this.current = this.gridOptions.paginationGetCurrentPage() + 1;
        this.totalPages = this.gridOptions.paginationGetTotalPages();
      }
    },
    handleFilesBeforeUpload(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      files.forEach((file) => this.filesToUpload.push(file));
    },
    removeFile(index) {
      let arrayFile = Array.from(this.filesToUpload);
      arrayFile.splice(index, 1);

      this.filesToUpload = arrayFile;
    },
    onFilterFiles() {
      this.gridOptions.setQuickFilter(this.search);
    },
    async listFiles() {
      try {
        const response = await this.$http.get(
          "/p/chat/ai/fine-tuning/files?acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.currentChat
        );
        this.$store.dispatch("acc/setAiFiles", response.data.data);
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async handleUpload() {
      this.popupUpload = false;
      this.$vs.loading();

      let fd = new FormData();

      this.filesToUpload.forEach((item, i) => {
        fd.append(`files${i}`, item);
      });

      try {
        const response = await this.$http.post(
          "/p/chat/ai/fine-tuning/files/save?" +
            "acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.currentChat,
          fd,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.accessToken,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: "Conteúdo adicionado com sucesso.",
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });

        this.filesToUpload = [];

        this.$store.dispatch("acc/setAiFiles", [
          ...this.rowData,
          ...response.data.data,
        ]);
      } catch (e) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: e.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
      this.$vs.loading.close();
    },
    async trainingFineTuning() {
      window.analytics.track(
        "Treinar IA",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      try {
        const response = await this.$http.post(
          "/p/chat/ai/fine-tuning/create?" +
            "acc=" +
            this.$store.state.acc.current_acc.id +
            "&chat=" +
            this.currentChat
        );

        this.fineTunningStatus = response.data.data;
        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: "Treinando IA.",
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });
      } catch (e) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: e.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    clickPopupUpload() {
      window.analytics.track(
        "Add IA",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.popupUpload = true;
    },
  },
  watch: {
    async currentChat() {
      await this.listFiles();
      await this.getFineTunningStatus();
    },
    rowData() {
      this.gridOptions.setRowData(this.rowData);
    },
  },
};
</script>
<style>
.knowledge-table .ag-cell {
  --ag-internal-padded-row-height: unset;
}
.knowledge-table .ag-header-cell:last-child .ag-header-cell-label {
  justify-content: center;
}
</style>
