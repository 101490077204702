<template>
  <div :class="background" class="flex items-center justify-between rounded-lg p-2">
    <div class="flex items-center">
      <feather-icon :icon="icon" v-if="icon" svgClasses="w-4 h-4 mr-3" />
      <span :class="textColor" class="text-base" v-html="label"></span>
    </div>
    <div class="flex">
      <vs-button
        v-if="edit"
        :color="color"
        size="small"
        icon="icon-edit"
        icon-pack="feather"
        @click="$emit('edit')"
      />
      <vs-button
        v-if="remove"
        class="ml-2"
        :color="color"
        size="small"
        icon="icon-trash"
        icon-pack="feather"
        @click="$emit('remove')"
      />
      <vs-tooltip text="Este motivo não pode ser removido" v-if="lockIcon">
        <vs-button disabled color="black" size="small" type="flat" icon-pack="feather" icon="icon-lock"></vs-button>
      </vs-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomTag",
  props: {
    color: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    edit: {
      type: [Function, Boolean],
      required: false
    },
    remove: {
      type: [Function, Boolean],
      required: false
    },
    lockIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: [String, Boolean],
      required: false
    }
  },
  computed: {
    background() {
      return 'bg-' + this.color + '-light'
    },
    textColor() {
      return 'text-' + this.color
    }
  }
}
</script>
