<template>
  <vs-row>
    <vs-col vs-w="12">
      <p class="pb-3 text-base font-semibold">
        {{ $t("AiPromptTemplateSugestionLabel") }}
      </p>
      <vs-textarea
        v-model="templateSuggestion"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="3"
        :placeholder="$t('AiPromptTemplateSugestionLegend')"
      />
      <span
        class="opacity-80 sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        >{{ $t("AiPromptTemplateSugestionLegend") }}</span
      >
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <p class="pb-3 text-base font-semibold">
        {{ $t("AiPromptAnswerSugestionLabel") }}
      </p>
      <vs-textarea
        v-model="answerSuggestion"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="3"
        :placeholder="$t('AiPromptAnswerSugestionLegend')"
      />
      <span
        class="opacity-80 sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        >{{ $t("AiPromptAnswerSugestionLegend") }}</span
      >
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <p class="pb-3 text-base font-semibold">{{ $t("AiPromptChatSummaryLabel") }}</p>
      <vs-textarea
        v-model="chatSummaryPrompt"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="3"
        :placeholder="$t('AiPromptChatSummaryLegend')"
      />
      <span
        class="opacity-80 sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        >{{ $t("AiPromptChatSummaryLegend") }}</span
      >
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <p class="pb-3 text-base font-semibold">{{ $t("AiPromptSentimentAnalysisLabel") }}</p>
      <vs-textarea
        v-model="sentimentAnalysis"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="3"
        :placeholder="$t('AiPromptSentimentAnalysisLegend')"
      />
      <span
        class="opacity-80 sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        >{{ $t("AiPromptSentimentAnalysisLegend") }}</span
      >
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <p class="pb-3 text-base font-semibold">{{ $t("AiPromptFeedbackResumeLabel") }}</p>
      <vs-textarea
        v-model="feedbackResume"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="3"
        :placeholder="$t('AiPromptFeedbackResumeLegend')"
      />
      <span
        class="opacity-80 sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        >{{ $t("AiPromptFeedbackResumeLegend") }}</span
      >
    </vs-col>
    <vs-col vs-w="12" class="mt-8">
      <div class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3 text-right">
        <vs-button color="success" @click.prevent="savePromptSettings">{{
          $t("Save")
        }}</vs-button>
      </div>
    </vs-col>
  </vs-row>
</template>
<script>
export default {
  name: "PromptSettings",
  props: {
    inboxData: {
      type: Object,
    },
  },
  data() {
    return {
      prompts: [
        {
          type: "templateSuggestion",
          message:
              "Crie uma mensagem curta de marketing para WhatsApp com emojis e com o foco na persuasão do cliente. Nunca inclua hashtags.",
        },
        {
          type: "answerSuggestion",
          message:
              "Sugerir resposta curtas, com no máximo 240 caracteres, para última pergunta do cliente e sempre terminar o texto com um followup. Se não houver perguntas em aberto, sempre fazer follow-up com o cliente.",
        },
        {
          type: "chatSummary",
          message:
              "Veja o texto da conversa entre um cliente e um atendente, você deve fazer o resumo da conversa (tl;dv) e uma análise dos próximos passos para o atendente. Faça o resumo sempre na linguagem da conversa.",
        },
        {
          type: "sentimentAnalysis",
          message: "Veja o texto da conversa entre um cliente e um atendente, você deve fazer a análise do sentimento do cliente e dizer se é neutro, positivo ou negativo."
        },
        {
          type: "feedbackResume",
          message: "Veja as pesquisas de satisfação e faça um resumo dos pontos positivos e negativos.",
        },
      ],
      templateSuggestion: "Crie uma mensagem curta de marketing para WhatsApp com emojis e com o foco na persuasão do cliente. Nunca inclua hashtags.",
      answerSuggestion: "Sugerir resposta curtas, com no máximo 240 caracteres, para última pergunta do cliente e sempre terminar o texto com um followup. Se não houver perguntas em aberto, sempre fazer follow-up com o cliente.",
      chatSummaryPrompt: "Veja o texto da conversa entre um cliente e um atendente, você deve fazer o resumo da conversa (tl;dv) e uma análise dos próximos passos para o atendente. Faça o resumo sempre na linguagem da conversa.",
      sentimentAnalysis: "Veja o texto da conversa entre um cliente e um atendente, você deve fazer a análise do sentimento do cliente e dizer se é neutro, positivo ou negativo.",
      feedbackResume: "Veja as pesquisas de satisfação e faça um resumo dos pontos positivos e negativos.",
    };
  },
  watch: {
    templateSuggestion(val) {
      this.updatePrompt("templateSuggestion", val, this.$t("AiPromptTemplateSugestionPlaceholder"));
    },
    answerSuggestion(val) {
      this.updatePrompt("answerSuggestion", val, this.$t("AiPromptAnswerSugestionPlaceholder"));
    },
    chatSummaryPrompt(val) {
      this.updatePrompt("chatSummary", val);
    },
    sentimentAnalysis(val) {
      this.updatePrompt("sentimentAnalysis", val, this.$t("AiPromptSentimentAnalysisPlacehold"));
    },
    feedbackResume(val) {
      this.updatePrompt("feedbackResume", val, this.$t("AiPromptFeedbackResumePlaceholder"));
    },
  },
  mounted() {
    if (
      this.inboxData &&
      this.inboxData.ia &&
      this.inboxData.ia.prompts &&
      this.inboxData.ia.prompts.length > 0
    ) {
      this.templateSuggestion = this.findPromptByType(this.inboxData.ia.prompts, "templateSuggestion") || this.findPromptByType(this.prompts, "templateSuggestion");
      this.answerSuggestion = this.findPromptByType(this.inboxData.ia.prompts, "answerSuggestion") || this.findPromptByType(this.prompts, "answerSuggestion");
      this.chatSummaryPrompt = this.findPromptByType(this.inboxData.ia.prompts, "chatSummary") || this.findPromptByType(this.prompts, "chatSummary");
      this.sentimentAnalysis = this.findPromptByType(this.inboxData.ia.prompts, "sentimentAnalysis") || this.findPromptByType(this.prompts, "sentimentAnalysis");
      this.feedbackResume = this.findPromptByType(this.inboxData.ia.prompts, "feedbackResume") || this.findPromptByType(this.prompts, "feedbackResume");
    }
  },
  methods: {
    savePromptSettings() {
      window.analytics.track(
        "Editar Duotalk IA",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$http
        .put(
          `/p/chat/inbox/config`,
          {
            ia: {
              prompts: this.prompts,
            },
          },
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        )
        .then(async () => {
          this.$emit("get-inbox");
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: this.$t("Success"),
            text: this.$t("AddedSuccessfuly"),
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "error",
            title: this.$t("Error"),
            text: this.$t("ErrorSavingConfiguration"),
            position: "top-right",
            time: 4000,
          });
        });
    },
    findPromptByType (prompts, type) {
      const message = prompts.find(elm => elm.type === type);
      return message && message.message ? message.message : "";
    },
    updatePrompt(type, val, placeholder = '') {
      const index = this.prompts.findIndex(elm => elm.type === type);
      const defaultMessage = index > -1 ? this.prompts[index].message : placeholder;
      const data = {
        type: type,
        message: val.length === 0 ? defaultMessage : val,
      };
      if (index > -1) {
        this.prompts[index] = data;
      } else {
        this.prompts.push(data);
      }
    },
  },
};
</script>
