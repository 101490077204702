<!-- =========================================================================================
  File Name: DuotalkIaSettings.vue
  Description: Duotalk IA Settings
========================================================================================== -->

<template>
  <vs-row>
    <vs-col vs-w="12">
      <vs-card class="p-4" v-if="inboxLoaded">
        <vs-button
          :type="currentTab === 0 ? 'filled' : 'flat'"
          @click="activeTab = 0"
        >
          Geral
        </vs-button>
        <vs-button
          :type="currentTab === 1 ? 'filled' : 'flat'"
          @click="activeTab = 1"
          class="ml-3"
        >
          Prompts pré-definido
        </vs-button>
        <vs-button
          :type="currentTab === 2 ? 'filled' : 'flat'"
          @click="activeTab = 2"
          class="ml-3"
          v-if="$store.state.acc.current_acc.assistantEnable"
        >
          Assistente
        </vs-button>
        <vs-button
          :type="currentTab === 3 ? 'filled' : 'flat'"
          @click="activeTab = 3"
          class="ml-3 hidden"
          v-if="
            this.currentAcc === '60ad40a8cf24431d122b2433' ||
            this.currentAcc === '608c60d25e9671ec25384820'
          "
        >
          Base de conhecimento
        </vs-button>
        <vs-divider />
        <general-settings
          :inboxData="inboxData.inbox"
          @get-inbox="getInbox"
          v-if="currentTab === 0"
        />
        <prompt-settings
          :inboxData="inboxData.inbox"
          @get-inbox="getInbox"
          v-else-if="currentTab === 1"
        />
        <assistant
          :inboxData="inboxData.inbox"
          @get-inbox="getInbox"
          v-else-if="currentTab === 2"
        />
        <knowledge-base
          :inboxData="inboxData.inbox"
          @get-inbox="getInbox"
          v-else-if="currentTab === 3"
        />
      </vs-card>
    </vs-col>
  </vs-row>
</template>
<script>
import GeneralSettings from "./GeneralSettings.vue";
import PromptSettings from "./PromptSettings.vue";
import KnowledgeBase from "./KnowledgeBase.vue";
import Assistant from "./Assistant.vue";

export default {
  name: "DuotalkIaSettings",
  components: {
    KnowledgeBase,
    PromptSettings,
    GeneralSettings,
    Assistant,
  },
  props: {
    setActiveTab: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      activeTab: null,
      inboxData: {},
      inboxLoaded: false,
    };
  },
  computed: {
    currentTab() {
      return this.activeTab !== null ? this.activeTab : this.setActiveTab;
    },
    currentAcc() {
      return this.$store.state.acc.current_acc.id;
    },
    chatOptions() {
      return this.$store.state.chat.chats;
    },
  },
  methods: {
    getInbox() {
      this.inboxIntentionsEntities = [];
      this.$vs.loading();
      this.$http
        .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          if (!this.chatOptions || this.chatOptions.length <= 0)
            await this.getChats();

          this.$vs.loading.close();
          this.inboxData = response.data.data;
          this.inboxLoaded = true;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (response) => {
          if (response && response.data && response.data.data && response.data.data.length > 0) {
            this.currentChat = response.data.data[0]._id;

            for (let i = 0; i < response.data.data.length; i++) {
              await this.$store.dispatch("chat/setChatsItem", {
                label: response.data.data[i].name,
                value: response.data.data[i]._id,
              });
            }
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  mounted() {
    this.getInbox();
  },
};
</script>
