<template>
  <div>
    <div v-if="!disabledEdit" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
      <feather-icon
        v-if="hasInbox"
        icon="EditIcon"
        svgClasses="h-5 w-5 hover:text-success cursor-pointer ml-4"
        @click="openEdit"
      />
    </div>

    <vs-popup class="holamundo" :title="$t('Edit')" :active.sync="popupActive">
      <div class="flex flex-wrap">
        <div class="w-full p-2">
          <h3 class="py-1">{{title}}</h3>
          <p>
             <vs-textarea
                name="email"
                v-model="text"
                class="w-full my-3"
                rows="5"
                placeholder="Escreva uma mensagem..."
              >
             </vs-textarea>
             <small>
              {{smallText}}
            </small>
          </p>
          
          <vs-button
            icon-pack="feather"
            icon="icon-file-plus"
            size="medium"
            class="mt-4 mb-4 md:mb-0 "
            @click="edit()"
            >{{ $t("Save") }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popupActive: false,
      id: "",
      title: "",
      text: "",
      type: "",
      default: "",
      status: "",
    };
  },
  name: "CellRendererActions",
  computed: {
    currentOperator() {
      return this.params.data.operatorData._id;
    },
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan
        .planParameters;
    },
    hasInbox: function () {
      return this.planData["inboxAvailable"];
    },
    usersData() {
      return this.$store.state.acc.current_acc_users;
    },
    
    smallText() {
      if(this.type === "start_attendance") return "* Digite {ATENDENTE} para adicionar o nome do atendente e {ID_CONVERSA} para adicionar o ID da conversa "
      else if(this.type === "operator_forward") return "* Digite {ATENDENTE1} para adicionar o nome do atendente de origem e {ATENDENTE2} para o nome do atendente de destino e {ID_CONVERSA} para adicionar o ID da conversa "
      else if(this.type === "nps_survey" || this.type === "csat_survey") return "* Digite {LINK} para adicionar o link da pesquisa e {ID_CONVERSA} para adicionar o ID da conversa "

      return ""
    },
    disabledEdit() {
      return this.status === 0;
    }
  },
  mounted() {
      const autoMessage = this.$store.state.chat.inboxAutoMessages.find(i => i.type === this.params.value);
      this.type = autoMessage.type;
      this.id = autoMessage._id;
      this.text = autoMessage.text;
      this.default = autoMessage.default;
      this.status = autoMessage.status;
  },
  methods: {
    edit() {
      if(this.default) {
        this.$http
          .post(`/p/chat/inbox/autoMessages`, {
            type: this.type,
            text: this.text,
            status: 1,
          }, 
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          })
          .then(async () => {
            this.$vs.loading.close();
            this.getAutoMessages();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$http
          .put(`/p/chat/inbox/autoMessages/${this.id}`,{
            text: this.text,
            status: 1,
          }, 
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          })
          .then(async () => {
            this.$vs.loading.close();
            this.getAutoMessages();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
    confirmInactivateRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmInactivateAutoMessageTitle"),
        text: this.$t("ConfirmInactivateAutoMessage"),
        accept: this.inactivateRecord,
        acceptText: this.$t("Inactivate"),
        cancelText: this.$t("Cancel"),
      });
    },
    confirmActivateRecord() {
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: this.$t("ConfirmActivateAutoMessageTitle"),
        text: this.$t("ConfirmActivateAutoMessage"),
        accept: this.activateRecord,
        acceptText: this.$t("Activate"),
        cancelText: this.$t("Cancel"),
      });
    },
    inactivateRecord() {
      if(this.default) {
        this.$http
          .post(`/p/chat/inbox/autoMessages`, {
            type: this.type,
            text: this.text,
            status: 0,
          }, 
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          })
          .then(async () => {
            this.$vs.loading.close();
            this.showInactivateSuccess();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$http
        .put(`/p/chat/inbox/autoMessages/${this.id}`,{
          status: 0
        }, 
        {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async () => {
          this.$vs.loading.close();
          this.showInactivateSuccess();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      }
    },
    activateRecord() {
        this.$http
        .put(`/p/chat/inbox/autoMessages/${this.id}`,{
          status: 1
        }, 
        {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async () => {
          this.$vs.loading.close();
          this.showActivateSuccess();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    showInactivateSuccess() {
      this.getAutoMessages();
      this.$vs.notify({
        color: "success",
        title: this.$t("Confirm"),
        text: this.$t("User") + " " + this.$t("InactivateSuccessfully"),
        position: "top-right",
        time: 4000
      });
    },
    showActivateSuccess() {
      this.getAutoMessages();
      this.$vs.notify({
        color: "success",
        title: this.$t("Confirm"),
        text: this.$t("User") + " " + this.$t("ActivateSuccessfully"),
        position: "top-right",
        time: 4000
      });
    },
    getAutoMessageTitle(type) {
      switch(type) {
        case "start_flow":
          return this.$t('FlowStart')
        case "start_attendance":
          return this.$t('StartAttendance')
        case "operator_forward":
          return this.$t('OperatorForward')
        case "nps_survey":
          return this.$t('CloseConversationNpsSurvey')
        case "csat_survey":
          return this.$t('CloseConversationCsatSurvey')
      }
    },
    getAutoMessages() {
      this.$vs.loading();
      this.$http
        .get("/p/chat/inbox/autoMessages", {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then(async (response) => {
          this.$vs.loading.close();
          const inboxAutoMessagesFiltered = response.data.data.map(message => {
            message.title = this.getAutoMessageTitle(message.type);
            return message;
          });
          
          await this.$store.dispatch(
            "chat/setInboxAutoMessages",
            inboxAutoMessagesFiltered
          );
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openEdit() {
      this.popupActive = true;
    }
  },
};
</script>
