<template>
  <div class="flex justify-end">
    <vs-tooltip
      v-if="params.data.type === 'Url'"
      class="self-center mr-3 mt-2"
      :text="`Atualizado em ${formatDate(tr.updatedAt)} (Atualização diária)`"
    >
      <vs-icon icon-pack="feather" icon="icon-info" class="text-grey">
      </vs-icon>
    </vs-tooltip>
    <vs-button
      v-if="params.data.type !== 'Url'"
      type="flat"
      color="dark"
      icon-pack="feather"
      icon="icon-download"
      class="mt-1"
      :href="params.data.url"
    />
    <vs-button
      v-else
      type="flat"
      color="dark"
      icon-pack="feather"
      icon="icon-external-link"
      class="mt-1"
      target
      :href="params.data.url"
    />
    <vs-button
      type="flat"
      color="danger"
      icon-pack="feather"
      icon="icon-trash"
      class="mt-1"
      @click="popupDeleteFile = true"
    />

    <vs-popup title="Deletar conteúdo" :active.sync="popupDeleteFile">
      <vs-row>
        <vs-col>
          <h5 class="text-center">
            Tem certeza que deseja remover o conteúdo - {{ params.data.name }}
          </h5>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="danger"
            icon="icon-trash"
            icon-pack="feather"
            icon-after
            @click="deleteFile(params.data._id)"
            class="px-2"
          >
            {{ $t("Remove") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import moment from "moment";
import axios from "../../http/axios";

export default {
  name: "AssistantBaseActions",
  data() {
    return {
      popupDeleteFile: false,
    };
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async deleteFile(id) {
      try {
        const response = await axios.delete(
          `/p/chat/ai/assistant/files/delete/${id}`,
          {
            params: {
              acc: this.params.data.acc,
              chat: this.params.data.chat || "Team Chat",
              isTeamChat: this.params.data.isTeamChat,
            },
          }
        );

        this.$store.dispatch("acc/removeAiFileById", id);

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: response.data.message,
          iconPack: "feather",
          icon: "icon-check-circle",
          color: "success",
          position: "top-right",
        });

        this.params.api.applyTransaction({
          remove: this.params.api.getSelectedRows(),
        });

        this.popupDeleteFile = false;
      } catch (e) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError") + " - " + e.response.data.message,
          iconPack: "feather",
          icon: "icon-x-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
  },
};
</script>
