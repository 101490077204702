<template>
  <vs-row vs-w="12">
    <vs-col vs-w="12">
      <p class="pb-3 text-base">{{ $t("AiGeneralSugestionLabel") }}</p>
      <vs-textarea
        v-model="suggestion"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="5"
        :placeholder="$t('AiGeneralSugestionPlaceholder')"
      />
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <p class="pb-3 text-base">{{ $t("AiGeneralAnswerLabel") }}</p>
      <vs-textarea
        v-model="answer"
        class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3"
        rows="5"
        :placeholder="$t('AiGeneralAnswerPlaceholder')"
      />
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <div class="sm:w-full md:w-full lg:w-full xl:w-1/2 xxl:w-1/3 text-right">
        <vs-button color="success" @click.prevent="saveGeneralSettings">{{
          $t("Save")
        }}</vs-button>
      </div>
    </vs-col>
  </vs-row>
</template>
<script>
export default {
  name: "GeneralSettings",
  props: {
    inboxData: {
      type: Object,
    },
  },
  data() {
    return {
      general: [],
      suggestion: "",
      answer: "",
    };
  },
  watch: {
    suggestion(val) {
      const index = this.general.findIndex((elm) => elm.type === "suggestion");
      const data = {
        type: "suggestion",
        message: val,
      };
      if (index > -1) this.general[index] = data;
      else this.general.push(data);
    },
    answer(val) {
      const index = this.general.findIndex((elm) => elm.type === "answer");
      const data = {
        type: "answer",
        message: val,
      };
      if (index > -1) this.general[index] = data;
      else this.general.push(data);
    },
  },
  mounted() {
    if (
      this.inboxData &&
      this.inboxData.ia &&
      this.inboxData.ia.general &&
      this.inboxData.ia.general.length > 0
    ) {
      const suggestion = this.inboxData.ia.general.find(
        (elm) => elm.type === "suggestion"
      );
      if (suggestion) this.suggestion = suggestion.message;
      const answer = this.inboxData.ia.general.find(
        (elm) => elm.type === "answer"
      );
      if (answer) this.answer = answer.message;
    }
  },
  methods: {
    saveGeneralSettings() {
      window.analytics.track(
        "Editar Duotalk IA",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      this.$vs.loading();
      this.$http
        .put(
          `/p/chat/inbox/config`,
          {
            ia: {
              general: this.general,
            },
          },
          {
            params: { acc: this.$store.state.acc.current_acc.id },
          }
        )
        .then(async () => {
          this.$emit("get-inbox");
          this.$vs.loading.close();
          this.$vs.notify({
            color: "success",
            title: this.$t("Success"),
            text: this.$t("AddedSuccessfuly"),
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "error",
            title: this.$t("Error"),
            text: this.$t("ErrorSavingConfiguration"),
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>
<style>
.vs-textarea::placeholder {
  opacity: 0.7;
}
</style>
